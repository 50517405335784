
/*=====================
01. General css start
==========================*/

body {
  font-family: $font_0, $font_1;
  position: relative;
  background: white;
  font-size: 14px;
  color: black;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font_0, $font_1;
  font-weight: normal;
}

h1 {
  font-size: calc(26px + (50 - 26) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 700;
  letter-spacing: 0.03em;
  line-height: 1.1;
}

h2 {
  font-size: calc(22px + (36 - 22) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 700;
  letter-spacing: 0.001em;
  line-height: 32px;
  color: black;
}

h3 {
  font-size: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 400;
  letter-spacing: 0.03em;
  line-height: 1.2;
  color: black;
}

h4 {
  font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 400;
  letter-spacing: 0.03em;
  line-height: 1.2;
}

h5 {
  font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 400;
  letter-spacing: 0.03em;
  line-height: 1.2;
}

h6 {
  font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.03em;
}

ul {
  padding-left: 0;
  margin-bottom: 0;
}

li {
  display: inline-block;
  font-size: 14px;
  letter-spacing: 0.03em;
}

p {
  font-size: 14px;
  line-height: 18px;
  color: $grey-light;
  letter-spacing: 0.03em;
}

a {
  transition: 0.5s ease;
  letter-spacing: 0.03em;
  &:hover {
    text-decoration: none;
    transition: 0.5s ease;
  }
  &:focus {
    outline: none;
  }
}

label {
  letter-spacing: 0.03em;
}

button,
.btn {
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

section,
.section-t-space {
  padding-top: 100px;
}

.section_space {
  padding-top: calc(30px + (100 - 30) * ((100vw - 320px) / (1920 - 320)));
}

.section-b-space {
  padding-bottom: 100px;
}

.medium-section {
  padding-top: 80px;
  padding-bottom: 80px;
}

.small-section {
  padding-top: 70px;
  padding-bottom: 70px;
}

.xs-section {
  padding: 30px 0;
}

.parallax-img {
  background-attachment: fixed;
}

.radius-cls {
  border-radius: 20px;
  .social-box {
    border-radius: 6px;
  }
}

.bg-inner {
  background-color: $inner-bg;
}

.lh-cls {
  line-height: 1 !important;
}

.w-80 {
  width: 80%;
}

.mb-down {
  margin-bottom: -30px;
}

.form-control {
  height: auto;
}

.radio_animated {
  cursor: pointer;
  margin-left: -1.25rem;
  position: relative;
  margin-right: 12px;
  &:before {
    transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transform: scale(0, 0);
    content: "";
    position: absolute;
    top: 0;
    left: 0.125rem;
    z-index: 1;
    width: 0.75rem;
    height: 0.75rem;
    background: $theme-color1;
    border-radius: 50%;
  }
  &:after {
    content: "";
    position: absolute;
    top: -0.25rem;
    left: -0.125rem;
    width: 1.25rem;
    height: 1.25rem;
    background: white;
    border: 2px solid #e8ebf2;
    border-radius: 50%;
  }
  &:checked:before {
    transform: scale(1, 1);
  }
}

.checkbox_animated {
  cursor: pointer;
  margin-left: -1.25rem;
  position: relative;
  margin-right: 12px;
  &:before {
    transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transform: rotate(-45deg) scale(0, 0);
    content: "";
    position: absolute;
    left: 0.25rem;
    top: 0.225rem;
    z-index: 1;
    width: 0.75rem;
    height: 0.375rem;
    border: 2px solid $theme-color1;
    border-top-style: none;
    border-right-style: none;
  }
  &:after {
    content: "";
    position: absolute;
    top: -0.125rem;
    left: 0;
    width: 1.3rem;
    height: 1.3rem;
    background: white;
    border: 2px solid #e8ebf2;
    cursor: pointer;
  }
  &:checked:before {
    transform: rotate(-45deg) scale(1, 1);
  }
}

.form-check {
  display: flex;
}

.mrg-cls {
  margin-bottom: -3px;
}

.rounded5 {
  border-radius: $radious5 !important;
}

.rounded10 {
  border-radius: $radious10 !important;
}

.rounded15 {
  border-radius: $radious15 !important;
}

.rounded20 {
  border-radius: $radious20 !important;
}

.rounded25 {
  border-radius: $radious25 !important;
}


/*Lazy load */

.blur-up {
  -webkit-filter: blur(5px);
  filter: blur(5px);
  transition: filter 400ms, -webkit-filter 400ms;
  &.lazyloaded {
    -webkit-filter: blur(0);
    filter: blur(0);
  }
}


/*=====================
1.1. Button start
==========================*/
.btn-group-showcase {
  margin-bottom: -20px;
  h3{
    margin-bottom: 15px;
  }
  .btn{
    margin-bottom: 20px;
    &+.btn{
      margin-left: 15px;
    }
  }
}
.btn-cards{
  .card{
    margin-bottom: 30px;
  }
}
.btn {
  line-height: 20px;
  font-size: 16px;
  font-weight: 700;
  border-radius: 0;
  transition: 0.3s ease-in-out;
  &.btn-sm {
    padding: 8px 24px !important;
  }
  &.white-btn {
    background-color: white !important;
    color: $theme-color1 !important;
    box-shadow: none !important;
  }
  &.black-btn {
    background-color: black !important;
    color: white !important;
    box-shadow: 1px 11px 20px 0 rgba(black, 0.12) !important;
    &:hover {
      box-shadow: 1px 11px 20px 0 rgba(black, 0.22);
      border: 1px solid black;
      background-color: white !important;
      color: black !important;
    }
  }
  &.btn-solid {
    background-color: $theme-color1;
    color: white;
    padding: 9px 20px;
    box-shadow: 1px 11px 20px 0 rgba($theme-color1, 0.12);
    &:hover {
      transition: 0.3s ease-in-out;
      background-color: white;
      color: $theme-color1;
      border: 1px solid $theme-color1;
      box-shadow: 1px 11px 20px 0 rgba($theme-color1, 0.22);
    }
    &.btn-outline {
      background-color: transparent;
      border: 1px solid $theme-color1;
      color: $theme-color1;
      &:hover {
        color: white;
        background-color: $theme-color1;
      }
    }
    &.color2 {
      background-color: $theme-color2;
      box-shadow: 2.5px 4.33px 25px 0 rgba($theme-color2, 0.3);
      &:hover {
        background-color: white;
        color: $theme-color2;
        transition: all 0.5s ease;
        border: 1px solid $theme-color2;
        box-shadow: 1px 11px 20px 0 rgba($theme-color2, 0.22);
      }
    }
  }
  &.btn-curve {
    border-radius: 5px;
    background-color: $theme-color1;
    color: white;
    padding: 9px 20px;
    box-shadow: 1px 11px 20px 0 rgba($theme-color1, 0.12);
  }
  &.btn-rounded {
    border-radius: 100px;
    color: white;
    padding: 10px 20px;
    &.color1 {
      background-color: $theme-color1;
      box-shadow: 1px 11px 20px 0 rgba($theme-color1, 0.12);
      &:hover {
        background-color: white;
        color: $theme-color1;
        transition: all 0.5s ease;
        border: 1px solid $theme-color1;
        box-shadow: 1px 11px 20px 0 rgba($theme-color1, 0.22);
      }
    }
    &.color2 {
      background-color: $theme-color2;
      box-shadow: 2.5px 4.33px 25px 0 rgba($theme-color2, 0.3);
      &:hover {
        background-color: white;
        color: $theme-color2;
        transition: all 0.5s ease;
        border: 1px solid $theme-color2;
        box-shadow: 1px 11px 20px 0 rgba($theme-color2, 0.22);
      }
    }
  }
  &:focus {
    box-shadow: none;
  }
}

.btn-outline {
  background-color: white;
  border: 1px solid $theme-color1;
  color: $theme-color1;
}


/*=====================
1.2. Title css start
==========================*/

.title-1 {
  text-align: center;
  .title-label {
    font-size: 14px;
    color: white;
    background-color: $theme-color1;
    text-transform: uppercase;
    padding: 4px 18px;
    line-height: 1.7;
    border-radius: 3px;
    font-family: $font_2;
    font-weight: 600;
    &.white-label {
      background-color: white;
      color: $theme-color1;
    }
  }
  h2 {
    padding-top: 25px;
    margin-bottom: 0;
    padding-bottom: 50px;
    font-weight: 800;
  }
  &.title-5 {
    .title-label {
      border-radius: 50px;
    }
    h2 {
      padding-top: 22px;
      padding-bottom: 15px;
    }
    p {
      font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
      font-weight: 600;
      margin-bottom: 50px;
    }
  }
  &.detail-title {
    .title-label {
      font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    }
    h2 {
      padding-bottom: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
    }
    p {
      max-width: 60%;
      margin: 0 auto;
      color: rgba(0, 0, 0, 0.41);
      font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
      line-height: 1.7;
    }
  }
  &.rounded {
    .title-label {
      border-radius: 12px;
    }
  }
}

.title-2 {
  text-align: center;
  h2 {
    font-size: calc(22px + (36 - 22) * ((100vw - 320px) / (1920 - 320)));
    color: $title-grey;
    font-weight: 400;
    margin-bottom: 22px;
    line-height: 32px;
    margin-top: -2px;
    span {
      color: $theme-color2;
      font-weight: 900;
    }
  }
  p {
    margin-bottom: 70px;
    color: $grey-1;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600;
  }
  &.color-1 {
    h2 {
      span {
        color: $theme-color1;
      }
    }
  }
}

.title-3 {
  text-align: center;
  .title-label {
    font-size: 14px;
    color: white;
    background-color: $theme-color1;
    padding: 4px 18px 6px;
    line-height: 1;
    font-family: $font_2;
    font-weight: 600;
  }
  h2 {
    font-size: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
    margin-top: 20px;
    margin-bottom: 90px;
    font-weight: 800;
    position: relative;
    span {
      font-family: $font_3, $font_4;
      font-size: calc(35px + (85 - 35) * ((100vw - 320px) / (1920 - 320)));
      position: absolute;
      color: rgba($theme-color1, 0.1);
      left: 50%;
      transform: translate(-50%, -50%);
      top: 50%;
      z-index: -1;
      font-weight: 400;
    }
  }
  &.detail-title {
    .title-label {
      font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    }
    h2 {
      padding-bottom: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
      margin-bottom: 30px;
    }
    p {
      max-width: 60%;
      margin: 0 auto;
      color: rgba(0, 0, 0, 0.41);
      font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
      line-height: 1.7;
      margin-bottom: 30px;
    }
  }
  &.rounded {
    .title-label {
      border-radius: 50px;
    }
  }
  &.white-title {
    .title-label {
      background-color: white;
      color: $theme-color1;
    }
    h2 {
      color: white;
      span {
        z-index: unset;
        color: rgba(white, 0.07);
      }
    }
  }
}

.title-basic {
  h2 {
    font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
    color: black;
    font-weight: 600;
  }
  p {
    margin-top: -4px;
    color: rgba(0, 0, 0, 0.7);
  }
}


/*=====================
1.3. Image-ratio start
==========================*/

.ratio_40 {
  .bg-size {
    &:before {
      padding-top: 40%;
      content: "";
      display: block;
    }
  }
}

.ratio_45 {
  .bg-size {
    &:before {
      padding-top: 45%;
      content: "";
      display: block;
    }
  }
}

.ratio2_1 {
  .bg-size {
    &:before {
      padding-top: 50%;
      content: "";
      display: block;
    }
  }
}

.ratio_55 {
  .bg-size {
    &:before {
      padding-top: 55%;
      content: "";
      display: block;
    }
  }
}

.ratio_59 {
  .bg-size {
    &:before {
      padding-top: 59%;
      content: "";
      display: block;
    }
  }
}

.ratio2_3 {
  .bg-size {
    &:before {
      padding-top: 60%;
      content: "";
      display: block;
    }
  }
}

.ratio3_2 {
  .bg-size {
    &:before {
      padding-top: 66.66%;
      content: "";
      display: block;
    }
  }
}

.ratio_90 {
  .bg-size {
    &:before {
      padding-top: 93%;
      content: "";
      display: block;
    }
  }
}

.ratio_landscape {
  .bg-size {
    &:before {
      padding-top: 75%;
      content: "";
      display: block;
    }
  }
}

.ratio_square {
  .bg-size {
    &:before {
      padding-top: 100%;
      content: "";
      display: block;
    }
  }
}

.ratio_asos {
  .bg-size {
    &:before {
      padding-top: 127.7777778%;
      content: "";
      display: block;
    }
  }
}

.ratio_portrait {
  .bg-size {
    &:before {
      padding-top: 150%;
      content: "";
      display: block;
    }
  }
}

.ratio1_2 {
  .bg-size {
    &:before {
      padding-top: 200%;
      content: "";
      display: block;
    }
  }
}

.b-top {
  background-position: top !important;
}

.b-bottom {
  background-position: bottom !important;
}

.b-center {
  background-position: center !important;
}

.b-left {
  background-position: left !important;
}

.b-right {
  background-position: right !important;
}

.b_size_content {
  background-size: contain !important;
}

.shadow-cls {
  box-shadow: 0 1px 9px 0 rgba(228, 228, 228, 0.7);
}

.container-medium {
  padding: 0 30px;
}

