/*=====================
2. Header section css
==========================*/

header {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  .menu {
    display: flex;
    margin: 0 auto;
    align-items: center;
    nav {
      margin: 0 auto;
    }
    .coupon-box {
      background-color: white;
      border-radius: 4px;
      padding: 3px;
      h6 {
        font-size: 14px;
        font-weight: 700;
        color: $theme-color1;
        margin-bottom: 0;
        border: 1px dashed rgba($theme-color1, 0.4);
        border-radius: 4px;
        padding: 5px 10px;
      }
    }
    .header-right {
      display: flex;
      align-items: center;
      .front-setting {
        background-color: rgba(255, 255, 255, 0.14);
        select {
          border: none;
          color: white;
          -webkit-appearance: none;
          padding: 5px 28px 4px 10px;
          text-transform: uppercase;
          font-size: 16px;
          font-weight: 900;
          background: transparent url(../../images/icon/down.png) no-repeat 80%;
          position: relative;
          cursor: pointer;
          option {
            color: black;
          }
          &:focus {
            outline: none;
          }
        }
      }
      .user {
        background-color: white;
        padding: 6px 12px;
        i {
          color: $theme-color2;
          font-size: 12px;
        }
        &.user-light {
          background-color: rgba(white, 0.14);
          i {
            color: white;
          }
        }
        &.bg_dark {
          background-color: $theme-color1;
        }
      }
      .setting {
        background-color: rgba(255, 255, 255, 0.14);
        padding: 6px 12px;
        display: none;
        position: relative;
        i {
          color: white;
          font-size: 12px;
        }
        .setting-open {
          position: absolute;
          width: 120px;
          right: 0;
          background-color: white;
          bottom: -84px;
          display: none;
          li {
            display: block;
            select {
              color: black;
              font-weight: 400;
              width: 100%;
            }
            +li {
              margin-left: 0;
            }
          }
          &.show {
            display: block;
          }
        }
      }
      .search-bar {
        background-color: rgba(255, 255, 255, 0.14);
        padding: 6px 12px;
        cursor: pointer;
        position: relative;
        i {
          color: white;
        }
        .form-control-search {
          top: 50px;
          position: absolute;
          transition: all 0.3s linear;
          right: 0;
          transform: translateY(-35px) scaleY(0);
          opacity: 0;
          visibility: hidden;
          margin-bottom: 0;
          input {
            width: 260px;
            background-color: rgba(255, 255, 255, 0.32);
            border: none;
            padding: 10px 10px 10px 70px;
            border-radius: 0;
            &:focus {
              outline: none;
            }
          }
          i {
            position: absolute;
            top: 16px;
            left: 20px;
            z-index: 1;
            color: rgba(black, 0.3);
          }
          &:before {
            position: absolute;
            content: "";
            width: 1px;
            height: 25px;
            background: #e8ebf2;
            left: 50px;
            top: 9px;
          }
          &.open {
            transform: translateY(0) scaleY(1);
            opacity: 1;
            visibility: visible;
            -webkit-transition: all 0.3s linear;
            transition: all 0.3s linear;
            z-index: 100;
          }
        }
      }
      li {
        +li {
          margin-left: 15px;
        }
      }
    }
  }
  &.overlay-white {
    background-color: rgba(white, 0.08);
  }
  &.overlay-black {
    background-color: rgba(black, 0.18);
  }
  &.inner-page {
    border-bottom: 1px solid rgba(white, 0.05);
  }
  &.light_header {
    background-color: white;
    position: relative;
    .main-navbar {
      .nav-menu {
        >li {
          >a {
            color: $grey-darker;
          }
        }
      }
    }
    .menu {
      .header-right {
        .front-setting {
          background-color: rgb(243, 243, 245);
          select {
            color: $grey-darker;
            background: url(../../images/icon/down-black.png) no-repeat 80%;
          }
        }
        .user {
          &.user-light {
            background-color: rgb(243, 243, 245);
            i {
              color: black;
            }
          }
        }
        .setting {
          background-color: rgb(243, 243, 245);
          i {
            color: black;
          }
        }
      }
      .brand-logo {
        img {
          filter: invert(1);
          width: 75%;
        }
      }
    }
    .main-navbar {
      .nav-menu {
        >li {
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }
    }
    .toggle-nav {
      i {
        color: black;
      }
    }
    &.absolute-header {
      position: absolute;
      box-shadow: 0 2px 4px 0 $box-shadow;
    }
  }
  &.fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
    box-shadow: 0 2px 4px 0 $box-shadow;
  }
  .mix-pills {
    margin-top: 15px;
    margin-bottom: 12px;
    &.nav-pills .nav-link.active,
    &.nav-pills .show>.nav-link {
      background-color: transparent;
      color: $theme-color1;
      svg {
        fill: $theme-color1;
      }
      &:after {
        opacity: 1;
      }
    }
    &.nav-pills {
      .nav-link {
        padding: 0 13px;
        color: black;
        position: relative;
        display: flex;
        align-items: center;
        font-size: 16px;
        svg {
          width: 14px;
          height: auto;
          margin-right: 8px;
        }
      }
    }
    .nav-item {
      margin-right: 15px;
    }
  }
  &.tour-header {
    z-index: 10;
  }
}

.header-sidebar {
  header {
    padding: 14px;
    .main-navbar {
      .nav-menu {
        position: fixed;
        background-color: white;
        width: 300px;
        border-radius: 0;
        border: 1px solid #eee;
        top: 0;
        height: 100vh;
        right: -300px;
        z-index: 99;
        transition: all 0.3s ease;
        overflow: scroll;
        &.opennav {
          right: 0;
        }
        .back-btn {
          display: block;
          width: 100%;
          padding: 0;
          .mobile-back {
            padding: 20px;
            font-size: 18px;
            color: $grey-darker;
            font-weight: 700;
            text-transform: uppercase;
            border-bottom: 1px solid #efefef;
            cursor: pointer;
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }
        }
        >li {
          display: block;
          float: none;
          width: 100%;
          padding: 0;
          a {
            padding: 10px 15px;
            font-size: 16px;
            font-weight: 700;
            position: relative;
            color: $grey-darker;
            .sub-arrow {
              right: 20px;
              position: absolute;
              &:before {
                content: "+";
                font-family: inherit;
                position: relative;
              }
            }
          }
          .nav-submenu {
            width: 100%;
            padding: 0;
            position: relative;
            display: none;
            opacity: 1;
            visibility: visible;
            &.opensubmenu {
              display: block;
            }
            li {
              .nav-sub-childmenu {
                display: none;
                position: relative;
                right: 0;
                width: 100%;
                padding: 0;
                &.opensubchild {
                  display: block;
                }
                li {
                  a {
                    padding: 0 45px;
                  }
                }
              }
              a {
                &:after {
                  display: none;
                }
                &:hover {
                  margin-left: 0;
                  &:after {
                    display: none;
                  }
                }
              }
              .level1 {
                li {
                  &:hover {
                    .level2 {
                      display: none;
                    }
                  }
                }
              }
              &:hover {
                .level1 {
                  display: none;
                }
              }
            }
          }
          .mega-menu-container {
            display: none;
            padding: 0;
            &.opensubmenu {
              display: block;
            }
            .mega-box {
              width: 100%;
              padding: 0;
              .link-section {
                .submenu-title {
                  position: relative;
                  h5 {
                    margin-bottom: 0;
                    font-weight: 400;
                    line-height: 1.9;
                    padding: 2px 25px;
                  }
                  .according-menu {
                    top: 4px;
                  }
                }
                .submenu-content {
                  display: none;
                  &.opensubmegamenu {
                    display: block;
                    padding: 0 45px;
                  }
                  ul {
                    li {
                      a {
                        line-height: 1.9;
                        &:hover {
                          margin-left: 0;
                          &:after {
                            display: none;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .nav-link {
            &.active {
              background-color: transparent;
              box-shadow: none;
              color: $grey-darker;
              transition: all 0.5s ease;
              &:hover {
                color: $theme-color1;
                transition: all 0.5s ease;
              }
            }
          }
          &:hover {
            .nav-submenu {
              //opacity: 0;
              //visibility: hidden;
              margin-top: 0;
            }
          }
        }
      }
    }
    .menu {
      nav {
        margin-left: auto;
        margin-right: calc(10px + (30 - 10) * ((100vw - 320px) / (1920 - 320)));
      }
    }
  }
  .mega-box {
    .link-section {
      .demo {
        ul {
          li {
            a {
              span {
                img {
                  top: 30px;
                  right: -60px;
                }
              }
            }
          }
        }
      }
    }
  }
  .mega-box {
    &.col {
      flex-basis: unset;
    }
  }
  .according-menu {
    position: absolute;
    right: 20px;
  }
  .main-menu {
    &.border-section {
      border: none;
    }
    .menu-left {
      .main-menu-right {
        .toggle-nav {
          position: absolute;
          z-index: 2;
          right: 145px;
          top: 6px;
          .sidebar-name {
            font-size: 18px;
            padding-left: 5px;
            text-transform: uppercase;
          }
        }
      }
    }
  }
  .sidebar-toggle {
    cursor: pointer;
    i {
      font-size: 20px;
      color: white;
    }
  }
  .mobile-back {
    display: block !important;
  }
}
