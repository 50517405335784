.popup {
  display: none;
  position: absolute;
  width: max-content;
  &.visible {
    display: block;
  }
  &.right {
    right: 0;
  }
}
