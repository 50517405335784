@import url('https://fonts.googleapis.com/css?family=Nunito:300,300i,400,400i,600,600i,700,700i,800,800i,900,900i&display=swap');
@import url('https://fonts.googleapis.com/css?family=Dancing+Script&display=swap');

@import "~@fortawesome/fontawesome-free/css/all.css";
@import "assets/scss/color1";

.nav-link, .slick-slider .slick-slide {
  cursor: pointer;
}
.react-datepicker-popper {
  z-index: 1000;
}

.filter-checkbox {
  cursor: pointer;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
  .check {
    font-size: 15px;
    color: rgb(150, 150, 150);
    .fa-check-square {
      color: #025dc8 !important;
    }
  }
  .count {
    font-size: 12px;
  }
}
