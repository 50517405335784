.responsiveDatepicker {
  text-align: center;
}


@media (max-width: 700px) {
  .responsiveDatepicker {
    width: 75%;
  }
  .customSearchButton {
    width: 75%;
  }
}
