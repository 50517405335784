/*=====================
    33.Responsive css start
==========================*/

@media (max-width: 1600px) {
    .top-category {
        .top_box {
            .right-content {
                padding: 0px 10px;
            }
        }
        .slick-slide {
            >div {
                margin: 0 20px;
            }
        }
        .slick-list {
            margin-left: -20px;
            margin-right: -20px;
        }
    }
}

@media (min-width: 1461px) {
    .container {
        max-width: 1430px;
    }
}

@media (max-width: 1460px) {
    .category-wrapper {
        .category-wrap {
            .category-content {
                .bottom {
                    display: block;
                    margin-left: 0;
                    .coupon-code{
                        margin-left: 0;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
    .routes-section .routes-box .routes-content .top-bar{
        display: block;
    }
    h2 {
        line-height: 28px;
    }
    .cab-section {
        .cab-content {
            .car-select {
                .btn {
                    width: 92px;
                    text-align: center;
                    font-size: 13px;
                }
            }
        }
    }
    .home_section {
        .home {
            .home-content {
                h1 {
                    margin-top: -10px;
                }
            }
        }
    }
    .mix-layout-section {
        .col-xl-10 {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .app-section {
        .app-box {
            padding: 50px;
        }
    }
    .service-section {
        .service-box {
            padding: 30px 20px;
        }
    }
    .testimonial-section {
        .testimonial {
            .right-part {
                margin-left: 10%;
            }
        }
    }
    .subscribe-footer {
        .service-left {
            .service-box {
                padding: 0 35px;
            }
        }
        .service-right {
            .input-group {
                input {
                    padding: 10px 18px;
                }
            }
            .btn-subscribe {
                padding: 8px 14px;
            }
        }
    }
    .subscribe_section {
        .subscribe-detail {
            padding-right: 25px;
        }
        .input-section {
            padding-left: 25px;
        }
    }
    .about-section {
        &.three-image {
            .about-text {
                padding-left: 0;
                h5,
                h3,
                p {
                    margin-bottom: 15px;
                }
                img {
                    width: 50%;
                }
            }
        }
        &.about_page {
            .image-section {
                .img-box {
                    .title-box {
                        padding: 15px;
                        min-width: 142px;
                    }
                }
            }
            .about-text {
                .buttons-about {
                    margin-top: 25px;
                }
                h2 {
                    span {
                        margin-top: 10px;
                    }
                }
                .title-3 {
                    margin-bottom: 25px;
                }
            }
        }
    }
    .ticket-section {
        .ticket-box {
            .content {
                padding: 30px 90px 30px 30px;
                .barcode-design {
                    right: 30px;
                    img {
                        height: 270px;
                    }
                }
            }
            .ticket-title {
                right: -13%;
            }
        }
        &.white-section {
            .ticket-box {
                .content {
                    .detail {
                        h4 {
                            margin-bottom: 10px;
                        }
                    }
                }
                .ticket-title {
                    right: -11%;
                }
            }
        }
    }
    .video-icon {
        width: 75px;
        height: 50px;
        span {
            border-top: 10px solid transparent;
            border-left: 20px solid white;
            border-bottom: 10px solid transparent;
        }
    }
    .video-section {
        .basic-section {
            h2 {
                margin-bottom: 25px;
            }
            h4 {
                margin-bottom: 35px;
            }
        }
    }
    .gallery-box {
        .gallery-content {
            h5 {
                margin-bottom: 10px;
                font-size: 16px;
            }
        }
    }
    .gallery_box {
        .gallery-content {
            bottom: 15px;
            left: 15px;
            h3 {
                margin-bottom: 5px;
                font-size: 20px;
            }
        }
        .view-cls {
            width: 30px;
            height: 30px;
            right: 15px;
            bottom: 15px;
        }
    }
    .blog-section {
        .blog-box {
            .img-part {
                img {
                    min-width: 260px;
                }
                .blog-date {
                    top: 20px;
                    left: 20px;
                    padding: 8px 10px;
                }
            }
            .blog-content {
                padding: 30px;
                p {
                    line-height: 1.3;
                }
            }
        }
    }
    .blog_section {
        .slick-slide {
            >div {
                margin: 0 15px;
            }
        }
        .slick-list {
            margin-left: -30px;
            margin-right: -30px;
        }
        .blog-wrap {
            .blog-details {
                padding: 30px 25px;
                h6 {
                    margin-bottom: 20px;
                }
                h5 {
                    margin-bottom: 10px;
                }
            }
            .blog-image {
                .blog-label {
                    padding: 10px 12px;
                    left: 25px;
                    bottom: 25px;
                }
            }
        }
        &.destination-section {
            .slick-slide {
                >div {
                    margin: 0 20px;
                }
            }
            .slick-list {
                margin-left: -20px;
                margin-right: -20px;
            }
            .destination-details {
                padding: 30px 20px;
            }
        }
    }
    .special-section {
        .slick-slide {
            >div {
                margin: 0 20px;
            }
        }
        .slick-list {
            margin-left: -20px;
            margin-right: -20px;
        }
        .special-box {
            .special-content {
                padding: 30px;
            }
        }
        &.grid-box {
            .special-box {
                .special-content {
                    padding: 20px;
                    h5 {
                        display: block;
                        span {
                            display: block;
                            margin-left: 0;
                        }
                    }
                    .tour-detail {
                        .include-sec {
                            ul {
                                li {
                                    font-size: 0;
                                }
                            }
                        }
                    }
                    &.restaurant-detail {
                        h5 {
                            span {
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }
    }
    .full-banner {
        .offer-text {
            left: -70%;
        }
    }
    .category-sec {
        .slick-slide {
            >div {
                margin: 0 15px;
            }
        }
        .slick-list {
            margin-left: -15px;
            margin-right: -15px;
        }
    }
    .category-wrapper {
        .category-wrap {
            .category-img {
                img {
                    width: 180px;
                    height: 180px;
                    border-radius: 20px;
                }
                .side-effect {
                    &:after {
                        left: 12px;
                    }
                    &:before {
                        left: 6px;
                    }
                }
            }
            .category-content {
                padding: 45px 30px 45px 156px;
                p {
                    display: block;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
        .slick-slide {
            >div {
                margin: 0 15px;
            }
        }
    }
    .top-category {
        padding: 0 35px;
        .top_box {
            .right-content {
                padding: 0px 20px;
            }
        }
    }
    .detail-section {
        .detail-box {
            padding: 0 30px;
        }
    }
    .routes-section {
        .routes-box {
            .routes-content {
                padding: 40px 20px 20px 20px;
            }
            .routes-img {
                .price-round {
                    width: 90px;
                    height: 90px;
                }
                .discount {
                    top: 20px;
                    left: 20px;
                }
            }
        }
    }
    .flight-detail {
        .flight-box {
            .middle-section {
                width: 69%;
            }
        }
    }
    .filter-section {
        .content {
            margin-left: -20px;
            margin-right: -20px;
            .grid-item {
                padding: 0 20px;
            }
        }
    }
    .menu-section {
        .menu-box {
            .bottom-bar {
                padding: 25px;
                .menu-bar {
                    img {
                        margin-right: 15px;
                        width: 50px;
                    }
                }
            }
        }
    }
    .book-table {
        .table-form {
            padding: 30px;
        }
    }
    .process-steps {
        .step-bg {
            .step-box {
                padding: 35px 25px;
            }
        }
    }
    .cab-full {
        .cab-search-section {
            .cab-search-box {
                .btn-solid {
                    padding: 2px 5px;
                }
            }
        }
        .left-bar {
            &.fixed-cls {
                width: 320px;
            }
        }
    }
    .scroll-body {
        padding-left: 320px;
    }
    .deals {
        .deals-box {
            .left-portion {
                flex: 0 0 33.33333%;
                max-width: 33.33333%;
            }
        }
    }
    .search-section {
        .search-box {
            .left-part {
                .search-body {
                    .quantity-right-plus,
                    .quantity-left-minus {
                        right: 10%;
                    }
                }
            }
        }
    }
    .video-slider {
        height: 180px;
    }
    footer {
        .footer {
            .footer-content {
                .footer-map {
                    iframe {
                        width: 100%;
                        height: 190px;
                    }
                }
                .contact-detail {
                    p {
                        margin-bottom: 20px;
                        -webkit-line-clamp: 2;
                    }
                    .contact-list {
                        li {
                            font-size: 14px;
                        }
                    }
                }
            }
            .footer-space {
                padding-left: 25px;
            }
        }
    }
    // inner pages
    .filter-panel {
        padding: 20px 25px;
    }
    .left-sidebar {
        .search-bar {
            padding: 25px;
            input {
                padding: 10px 10px 10px 60px;
            }
        }
        .middle-part {
            padding: 25px 25px;
        }
        .bottom-info {
            padding: 0 25px 25px;
        }
    }
    .list-view {
        .list-box {
            .list-content {
                h6 {
                    max-width: 300px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }
    }
    .top-filter-section {
        ul {
            .onclick-title {
                min-width: 130px;
            }
        }
    }
    .blog-inner {
        &.blog_section {
            .blog-list {
                .blog-wrap {
                    .blog-image {
                        width: 30%;
                    }
                    .blog-details {
                        width: 70%;
                    }
                }
            }
        }
    }
    .dashboard-section {
        .dashboard-box {
            .dashboard-detail {
                .card-payment {
                    .payment-card {
                        padding: 20px 10px;
                        min-height: auto;
                    }
                }
            }
        }
        .dashboard-sidebar {
            .profile-top {
                .profile-image {
                    .profile-edit {
                        right: 22%;
                    }
                }
            }
        }
    }
}

@media (max-width: 1460px) and (min-width: 991px) {
    .special-section {
        &.grid-box {
            .special-box {
                .special-content {
                    .bottom-section {
                        .price {
                            display: block;
                            .facility-detail {
                                margin-left: 0;
                                margin-top: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1460px) and (min-width: 1199px) {


    .category-section {
        .category-box {
            .top-bar {
                left: 10px;
                top: 15px;
            }
            .like-cls {
                right: 10px;
                top: 15px;
            }
            .bottom-bar {
                left: 10px;
                bottom: 15px;
                .top {
                    h5 {
                        font-size: 16px;
                        margin-right: 8px;
                        max-width: 115px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    h6 {
                        margin-left: 10px;
                    }
                }
            }
            &:hover {
                .bottom-bar {
                    bottom: 25px;
                }
            }
        }
    }
}

@media (max-width: 1367px) {
    .highlight-section{
        .highlight-box {   
            padding:30px 0px;  
            .content-sec p {
                width: 90%;        
            }  
        }
    }
    .title-3 h2 {
        margin-bottom: 50px;
    }
    .menu-section {
        .menu-box{
            .bottom-bar {
                .menu-bar {
                    padding-bottom: 20px;
                    margin-bottom: 20px;
                }
            }
        }
    }
    .app-section .app-content{
        text-align: center;
    }
    section,
    .section-t-space {
        padding-top: 90px;
    }
    .section-b-space {
        padding-bottom: 90px;
    }
    .arrow-classic {
        .slick-prev {
            left: -12px;
        }
        .slick-next {
            right: 0;
        }
    }
    .medium-section {
        padding-top: 70px;
        padding-bottom: 70px;
    }
    .small-section {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .home_section {
        .offer-text {
            left: -98px;
            h6 {
                letter-spacing: 0.1em;
            }
        }
    }
    .home_effect {
        .effect_image {
            height: 80vh;
        }
    }
    .service_section {
        >div {
            &:nth-last-child(-n + 3) {
                margin-top: 50px;
            }
        }
    }
    .service-part {
        .service-wrapper {
            padding: 30px;
            h3 {
                margin-bottom: 8px;
            }
            h6 {
                margin-bottom: 15px;
            }
            p {
                margin-bottom: 24px;
            }
        }
    }
    .testimonial-section {
        .testimonial {
            padding-bottom: 90px;
        }
    }
    .subscribe-section {
        .input-group {
            .btn {
                padding: 14px;
            }
        }
    }
    .about-section {
        .about-text {
            padding-left: 20px;
            h3,
            h2 {
                margin-bottom: 25px;
            }
            h5 {
                margin-bottom: 5px;
            }
            p {
                line-height: 1.4;
                margin-bottom: 15px;
            }
        }
    }
    .blog_section,
    .special-section {
        &.section-b-space {
            padding-bottom: 60px;
        }
    }
    .category-sec {
        &.section-b-space {
            padding-bottom: 50px;
        }
    }
    .category-wrapper {
        &.section-b-space {
            padding-bottom: 60px;
        }
    }
    .category-bg {
        &.section-b-space {
            padding-bottom: 80px;
        }
    }
    .cab-full {
        .location-option {
            display: block !important;
            [type="radio"]:checked+label:before,
            [type="radio"]:not(:checked)+label:before {
                top: 0;
            }
            .form-check {
                +.form-check {
                    margin-top: 5px;
                    margin-top: 0;
                }
            }
        }
        .cab-search-section {
            .cab-search-box {
                .btn-solid {
                    margin-left: -15px;
                }
            }
        }
    }
    .deals {
        .deals-box {
            .left-portion {
                flex: 0 0 37%;
                max-width: 37%;
            }
        }
    }
    // inner pages
    .portfolio-creative {
        .content-sec {
            p {
                max-width: 90%;
            }
        }
    }
}

@media (max-width: 1199px) {
    section,
    .section-t-space {
        padding-top: 80px;
    }
    .footer{
        padding-top: 100px;
    }
    .section-b-space {
        padding-bottom: 80px;
    }
    .medium-section {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .small-section {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .title-1 {
        h2 {
            padding-top: 20px;
            padding-bottom: 40px;
        }
        &.title-5 {
            h2 {
                padding-top: 18px;
                padding-bottom: 10px;
            }
            p {
                margin-bottom: 50px;
            }
        }
        &.detail-title {
            p {
                max-width: 90%;
            }
        }
    }
    .title-2 {
        h2 {
            margin-bottom: 18px;
        }
        p {
            margin-bottom: 50px;
        }
    }
    .title-3 {
        h2 {
            margin-bottom: 70px;
        }
        &.detail-title {
            p {
                max-width: 90%;
            }
        }
    }
    header {
        &.light_header {
            .main-navbar {
                .nav-menu {
                    >li {
                        padding-top: 0;
                        padding-bottom: 0;
                    }
                }
            }
        }
        &.fixed-header {
            position: relative;
        }
    }
    .search-section {
        margin-top: -45px;
    }
    .menu-overlay {
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.52);
        z-index: 2;
        display: none;
        &.show {
            display: block;
        }
    }
    .toggle-nav {
        padding-top: 25px;
        padding-bottom: 25px;
    }
    .breadcrumb-section {
        height: 430px;
        .breadcrumb-content {
            height: 430px;
            padding-top: 104px;
        }
        .title-breadcrumb {
            font-size: 440px;
        }
        &.breadcrumb-cum-header {
            height: 630px;
            .breadcrumb-content {
                height: 630px;
                p {
                    max-width: 68%;
                }
            }
        }
    }
    .home_section {
        .home {
            height: 90vh;
            .home-content {
                height: 90vh;
                padding-top: 74px;
                h1 {
                    margin-bottom: 0;
                }
                h5 {
                    margin-bottom: 15px;
                }
                h2 {
                    margin-bottom: 20px;
                }
                >div {
                    padding: 50px 110px;
                }
                &.food_content {
                    h1 {
                        margin-bottom: 20px;
                    }
                    .book-table {
                        .table-form {
                            padding: 25px 35px;
                        }
                    }
                }
                &.mix-layout {
                    &.smaller-content {
                        .search-panel {
                            .search-section {
                                .search-box {
                                    .left-part {
                                        .search-body {
                                            padding: 20px 10px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .slick-prev,
        .slick-next {
            padding: 25px;
        }
    }
    .cab-section {
        .cab-content {
            padding-top: 66px;
            .call-section {
                .call {
                    padding: 12px 16px;
                    margin-bottom: 22px;
                    i {
                        margin-right: 30px;
                    }
                    &:after {
                        width: 50px;
                        height: 50px;
                    }
                }
            }
            h2 {
                margin-bottom: 20px;
            }
            h3 {
                margin-bottom: 20px;
            }
            .car-select {
                ul {
                    li {
                        margin-right: 18px;
                    }
                }
                .btn {
                    width: 99px;
                }
            }
        }
        &.flight-section {
            .cab-content {
                padding-top: 74px;
            }
        }
    }
    .arrow-classic {
        .slick-prev {
            left: -10px;
        }
        .slick-next {
            right: 2px;
        }
    }
    .home_effect {
        .effect_image {
            background-size: cover !important;
            height: 70vh;
            .effect_content {
                top: 50% !important;
                transform: translateY(-50%);
                h1 {
                    font-size: calc(40px + (70 - 40) * ((100vw - 320px) / (1920 - 320)))!important;
                    text-shadow: 2px 2px 8px #548cc7;
                }
            }
        }
    }
    .app-section {
        .app-content {
            .title {
                line-height: 1;
            }
            h3 {
                margin-bottom: 22px;
            }
            p {
                line-height: 1.5;
                margin-bottom: 25px;
            }
        }
        .app-box {
            padding: 40px;
            h5,
            h2 {
                margin-bottom: 20px;
            }
            p {
                margin-bottom: 22px;
            }
            .app-buttons {
                .app-btn {
                    margin-left: 7px;
                }
            }
        }
        &.app-right-sec {
            .app-content {
                padding: 40px;
            }
        }
    }
    .service-section {
        >div {
            padding-left: 15px;
            padding-right: 15px;
        }
        .service-box {
            .service-icon {
                margin-bottom: 20px;
            }
            h3 {
                margin-bottom: 15px;
                font-size: 20px;
            }
            .btn {
                margin-top: 25px;
            }
        }
    }
    .service_section {
        >div {
            &:nth-last-child(-n + 3) {
                margin-top: 30px;
            }
        }
        .service-wrap {
            .service-icon {
                img {
                    margin-bottom: 20px;
                    height: 70px;
                }
            }
            h5 {
                margin-bottom: 8px;
            }
            p {
                margin-bottom: -4px;
                line-height: 1.5;
            }
        }
    }
    .service-part {
        .service-wrapper {
            h3 {
                font-size: 20px;
                i {
                    font-size: 12px;
                }
            }
            .service-btn {
                .btn {
                    padding: 6px 12px;
                }
            }
        }
    }
    .testimonial-section {
        .testimonial {
            padding-bottom: 80px;
            .right-part {
                margin-left: 14%;
            }
        }
        .slick-prev {
            left: -50px;
        }
        .slick-next {
            right: -50px;
        }
    }
    .testimonial_section {
        .testimonial {
            .top-part {
                .img-part {
                    margin-bottom: 45px;
                    margin-top: 60px;
                }
            }
            .bottom-part {
                p {
                    margin-bottom: 35px;
                }
            }
        }
    }
    .subscribe-footer {
        .service-left {
            .service-box {
                padding: 0 15px;
                .icon {
                    svg {
                        width: 30px;
                        height: 30px;
                    }
                }
            }
        }
        .service-right {
            .input-group {
                input {
                    padding: 7px 12px;
                }
            }
        }
    }
    .subscribe_section {
        .subscribe-detail {
            span {
                display: none;
            }
        }
        .input-section {
            input {
                padding: 35px 160px 35px 35px;
            }
            .btn {
                right: 35px;
            }
        }
    }
    .about-section {
        .about-text {
            h3,
            h2 {
                margin-bottom: 15px;
            }
            p {
                line-height: 1.3;
                margin-bottom: 10px;
            }
        }
        .image-section {
            .img-box {
                +.img-box {
                    margin-left: 40px;
                }
            }
        }
        &.three-image {
            .about-text {
                margin-top: 30px;
                img {
                    width: 200px;
                }
                h2 {
                    margin-bottom: 15px;
                    span {
                        display: unset;
                        font-size: unset;
                        text-transform: unset;
                    }
                }
            }
        }
        &.about_page {
            .image-section {
                .img-box {
                    width: 33.33%;
                    .title-box {
                        padding: 10px;
                        min-width: 130px;
                        h3 {
                            margin-bottom: 4px;
                        }
                    }
                    &:nth-child(odd) {
                        margin-bottom: 30px;
                        .title-box {
                            bottom: -30px;
                        }
                    }
                    &:nth-child(even) {
                        margin-top: 30px;
                        .title-box {
                            top: -30px;
                        }
                    }
                }
            }
        }
    }
    .ticket-section {
        .ticket-box {
            .content {
                padding: 25px 80px 25px 25px;
                .detail {
                    max-width: 295px;
                    h3,
                    h4 {
                        margin-bottom: 10px;
                    }
                    p {
                        margin-bottom: 15px;
                    }
                }
                .barcode-design {
                    right: 25px;
                    img {
                        height: 250px;
                    }
                }
            }
            .ticket-title {
                right: -15%;
            }
        }
        .slick-prev,
        .slick-next {
            right: 30px;
            &:before {
                padding: 6px;
            }
        }
        .slick-next {
            top: calc(50% - 20px);
        }
        .slick-prev {
            top: calc(50% + 20px);
        }
        &.white-section {
            .ticket-box {
                .ticket-title {
                    right: -13%;
                }
            }
        }
    }
    .video-section {
        padding: 90px;
        .basic-section {
            h4 {
                margin-bottom: 25px;
            }
        }
    }
    .video_section {
        padding: 90px 0;
        .video-content {
            h5 {
                margin-bottom: 20px;
            }
            h2 {
                margin-bottom: 15px;
            }
            p {
                margin-bottom: 18px;
            }
            .bottom-section {
                margin-top: 25px;
                .info-btn {
                    margin-left: 18px;
                }
            }
        }
        .offer-text {
            display: none;
        }
    }
    .timer {
        li {
            padding: 5px 15px;
            margin-right: 15px;
        }
    }
    .gallery-box {
        .gallery-content {
            .btn {
                padding: 6px 12px;
            }
        }
    }
    .gallery_box {
        .gallery-content {
            h3 {
                font-size: 18px;
            }
        }
    }
    .blog-section {
        .blog-box {
            .img-part {
                img {
                    min-width: 200px;
                }
                .blog-date {
                    padding: 6px 8px;
                }
            }
            .blog-content {
                padding: 15px;
                h5 {
                    margin-bottom: 8px;
                }
                p {
                    line-height: 1.1;
                    margin-bottom: 10px;
                }
                h6 {
                    margin-bottom: 10px;
                }
                .btn {
                    padding: 6px 10px;
                }
            }
        }
    }
    .blog_section {
        &.section-b-space {
            padding-bottom: 50px;
        }
        &.destination-section {
            .destination-details {
                h5 {
                    margin-bottom: 12px;
                }
                h2 {
                    margin-bottom: 12px;
                }
                h6 {
                    margin-bottom: 20px;
                }
            }
        }
    }
    .subscribe-footer {
        .service-left {
            float: unset;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 25px;
            .service-box {
                padding: 0 25px;
            }
        }
        .service-right {
            float: unset;
            width: 60%;
            margin: 0 auto;
        }
    }
    .special-section {
        &.section-b-space {
            padding-bottom: 50px;
        }
        &.grid-box {
            .special-box {
                .special-content {
                    .tour-detail {
                        h6 {
                            max-width: 410px;
                        }
                    }
                }
            }
        }
    }
    .full-banner {
        padding: 100px 0;
        .offer-text {
            left: 0;
            top: 0;
            position: relative;
            display: inline-block;
            margin-bottom: 16px;
            h6 {
                margin-top: 16px;
                margin-bottom: 0;
            }
        }
        .banner-content {
            h5 {
                margin-bottom: 20px;
            }
            h2 {
                margin-bottom: 20px;
            }
            p {
                line-height: 1.4;
            }
            .bottom-section {
                margin-top: 0;
                .info-btn {
                    margin-left: 15px;
                }
            }
        }
    }
    .category-sec {
        &.section-b-space {
            padding-bottom: 40px;
        }
    }
    .category-wrapper {
        .title-1 {
            &.title-5 {
                p {
                    margin-bottom: 20px;
                }
            }
        }
        &.section-b-space {
            padding-bottom: 50px;
        }
    }
    .category-bg {
        .category-block {
            padding: 80px 30px 40px;
            .rating {
                margin-bottom: 15px;
            }
            h6 {
                margin-bottom: 12px;
            }
            h5 {
                margin-bottom: 16px;
            }
        }
        &.section-b-space {
            padding-bottom: 70px;
        }
    }
    .detail-section {
        .detail-box {
            padding: 0 10px;
            .upper-part {
                margin-bottom: 30px;
                h2 {
                    padding-bottom: 20px;
                    margin-bottom: 20px;
                }
            }
        }
    }
    .routes-section {
        .routes-box {
            .routes-img {
                .price-round {
                    width: 80px;
                    height: 80px;
                }
            }
        }
    }
    .flight-detail {
        .row {
            >div {
                .flight-box {
                    +.flight-box {
                        margin-top: 40px;
                    }
                }
            }
        }
    }
    .about_section {
        .about_content {
            margin-left: 45px;
            h5 {
                margin-bottom: 20px;
            }
            h2 {
                margin-bottom: 20px;
            }
            p {
                margin-bottom: 25px;
            }
        }
    }
    .food-banner {
        padding: 140px 0;
        background-position: center;
    }
    .discount-banner {
        padding: 125px 0;
        .banner-content {
            h4 {
                margin-bottom: 24px;
            }
            h2 {
                margin-bottom: 24px;
            }
            .coupon {
                margin-bottom: 28px;
            }
        }
    }
    .book-table {
        .table-form {
            margin-bottom: 30px;
            border-radius: 15px;
            form {
                .form-group {
                    margin-right: 20px;
                }
                .gj-datepicker {
                    margin-right: 20px;
                }
            }
        }
        &.single-table {
            .table-form {
                margin-top: -60px;
            }
        }
    }
    .process-steps {
        .step-bg {
            .row {
                margin-left: -30px;
                margin-right: -30px;
                >div {
                    padding-left: 30px;
                    padding-right: 30px;
                }
                &.popular-section {
                    >div {
                        padding-left: 15px;
                        padding-right: 15px;
                    }
                }
            }
        }
    }
    .car-type-section {
        .type-box {
            padding: 15px;
        }
    }
    .home-section {
        .flight-left {
            padding-top: 74px;
        }
    }
    .deals {
        .deals-box {
            .left-portion {
                flex: 0 0 28%;
                max-width: 28%;
            }
        }
    }
    .cab-full {
        .left-bar {
            &.fixed-cls {
                position: relative;
                height: auto;
                box-shadow: none;
                width: 100%;
                .flight-search {
                    .flight-search-detail {
                        padding: 30px 15px 0;
                        form {
                            >div {
                                &:first-child {
                                    margin-top: 20px;
                                }
                            }
                        }
                        .location-option {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
    .pt-xl-cls {
        padding-top: 0;
    }
    .scroll-body {
        padding-left: 0;
    }
    .video-slider {
        height: 148px;
    }
    footer {
        .footer {
            .footer-content {
                .contact-detail {
                    .footer-logo {
                        margin-bottom: 25px;
                    }
                    p {
                        -webkit-line-clamp: 3;
                    }
                }
            }
            .footer-title {
                h5 {
                    margin-bottom: 30px;
                }
            }
            .footer-space {
                padding-left: 0;
            }
            .order-cls {
                order: -1;
            }
            .order-row {
                >div {
                    &:last-child {
                        margin-top: 30px;
                    }
                    &:nth-child(3) {
                        margin-top: 30px;
                    }
                }
            }
        }
    }
    // inner pages
    .team-section {
        .team-box {
            .team-content {
                min-width: 200px;
            }
        }
        .slick-dots {
            padding-top: 25px;
        }
    }
    .filter-panel {
        .collection-grid-view {
            display: none;
        }
    }
    .search-panel {
        .search-section {
            .search-box {
                .left-part {
                    .search-body {
                        padding: 20px 10px;
                        h6 {
                            margin-bottom: 3px;
                        }
                    }
                }
                .right-part {
                    margin: 0 20px;
                    .btn {
                        padding: 4px 12px;
                    }
                }
            }
        }
        &.sticky {
            .search-section {
                .search-box {
                    .left-part {
                        .search-body {
                            padding: 20px 10px;
                        }
                    }
                }
            }
        }
    }
    .single-section {
        .description-section {
            .description-details {
                .menu-part {
                    .rooms-box {
                        .room-detail {
                            padding: 10px 10px;
                            .facility-detail {
                                li {
                                    i {
                                        margin-right: 2px;
                                    }
                                }
                            }
                        }
                        .price-details {
                            .btn {
                                padding: 3px 9px;
                            }
                        }
                    }
                    &.facility {
                        .row {
                            >div {
                                &:nth-child(-n + 2) {
                                    margin-bottom: 20px;
                                }
                            }
                        }
                    }
                }
            }
            .menu-top {
                li {
                    a {
                        padding: 11px;
                    }
                }
            }
        }
    }
    .summery-box {
        .summery-section {
            .box {
                .left {
                    width: 49%;
                }
            }
        }
    }
    .list-view {
        .list-box {
            .list-content {
                h6 {
                    max-width: 220px;
                }
                .book-now {
                    padding: 3px 7px;
                }
            }
        }
    }
    .detail-menu {
        .nav-tabs {
            .nav-item {
                margin-right: 20px;
            }
        }
    }
    .order-menu-section {
        .order-section {
            .order-items {
                .items {
                    p {
                        max-width: 64%;
                    }
                }
            }
        }
    }
    .view-map {
        .arrow {
            left: 50px;
        }
    }
    .flight-detail-sec {
        .detail-bar {
            .detail-wrap {
                .airport-part {
                    .airport-progress {
                        &:after {
                            width: 40%;
                        }
                    }
                }
            }
        }
        &.full_width-detail {
            .detail-bar {
                .detail-wrap {
                    padding: 15px 0;
                    .logo-sec {
                        display: block;
                        img {
                            margin-bottom: 10px;
                            width: 50px;
                            margin-right: 0;
                        }
                    }
                }
            }
        }
        &.book_panel {
            .detail-bar {
                .detail-wrap {
                    .airport-part {
                        .airport-progress {
                            display: none;
                        }
                    }
                }
            }
            .grand-total {
                .btn {
                    padding: 6px 6px;
                }
            }
        }
        &.cab-detail-sec {
            .detail-bar {
                .detail-wrap {
                    .logo-sec {
                        img {
                            width: 120px;
                            margin-bottom: 5px;
                        }
                    }
                    .car-details {
                        ul {
                            +ul {
                                margin-left: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
    .top-bar-flight {
        .date-fare-slider {
            width: 85%;
        }
        .fare-calender {
            width: 15%;
        }
    }
    .top-filter-section {
        ul {
            .onclick-title {
                min-width: 110px;
            }
        }
    }
    .review-section {
        .review_box {
            .flight_detail {
                .logo-sec {
                    text-align: center;
                    img {
                        display: block;
                        margin: 0 auto;
                    }
                }
                .airport-part {
                    .airport-progress {
                        display: none;
                    }
                }
            }
        }
    }
    .blog-inner {
        &.blog_section {
            .blog-list {
                .blog-wrap {
                    .blog-details {
                        p {
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                    .blog-image {
                        width: 80%;
                    }
                }
            }
        }
    }
    .dashboard-section {
        .dashboard-main {
            .dashboard-intro {
                .complete-profile {
                    .row {
                        >div {
                            margin-top: 10px;
                            &:first-child {
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
            .counter-section {
                .row {
                    >div {
                        &:nth-child(-n + 2) {
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }
        .dashboard-box {
            .dashboard-detail {
                .booking-box {
                    .date-box {
                        width: 80px;
                    }
                    .detail-middle {
                        width: 65%;
                        .media {
                            .media-body {
                                margin-left: 15px;
                            }
                        }
                    }
                }
                .card-payment {
                    >div {
                        &:nth-child(3) {
                            margin-top: 30px;
                        }
                    }
                    .payment-card {
                        &.add-card {
                            .card-details {
                                min-height: 152px;
                                height: auto;
                            }
                        }
                    }
                }
            }
        }
    }
    .guest-detail {
        form {
            .submit-btn {
                .btn {
                    width: auto;
                }
            }
        }
    }
}

@media (max-width: 1199px) and (min-width: 767px) {
    .list-view {
        .list-box {
            .list-content {
                .hotel-info {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }
            }
        }
    }
}

@media (max-width: 1199px) and (min-width: 991px) {

    .category-wrapper {
        .category-wrap {
            .category-content {
                padding: 35px 30px 35px 154px;
                .top {
                    display: block;
                    margin-bottom: 5px;
                    h3 {
                        margin-bottom: 5px;
                    }
                    h6 {
                        margin-left: 0;
                    }
                }
                .rating {
                    margin-bottom: 8px;
                }
                .bottom {
                    display: block;
                    h3 {
                        margin-bottom: 5px;
                    }
                    .coupon-code {
                        margin-left: 0;
                    }
                }
                p {
                    margin-bottom: 10px;
                }
            }
        }
    }
    .flight-search {
        .flight-search-detail {
            .form-group {
                img {
                    display: none;
                }
                .form-control {
                    font-size: 14px;
                }
                .gj-datepicker {
                    .input-group-append {
                        display: none;
                    }
                }
            }
        }
    }
}

@media (max-width: 1024px) {
    .coming-soon {
        &.travel {
            .coming-soon-detail {
                align-items: center;
                padding-bottom: 0;
                background-color: rgba(white, 0.6);
            }
            svg {
                width: 100%;
                height: 100%;
            }
        }
    }
}

@media (max-width: 991px) and (min-width: 767px) {
    .category-section {
        .category-box {
            .top-bar {
                left: 10px;
                top: 15px;
            }
            .like-cls {
                right: 10px;
                top: 15px;
            }
            .bottom-bar {
                left: 10px;
                bottom: 15px;
                .top {
                    h5 {
                        font-size: 14px;
                        margin-right: 8px;
                        max-width: 105px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    h6 {
                        margin-left: 10px;
                    }
                }
            }
            &:hover {
                .bottom-bar {
                    bottom: 25px;
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .cab-section .cab-content .call-section .call i {
    padding-top: 0;
    padding-left: 0;
}
    .book-table.single-table{
        .table-form.classic-form {
            form .form-group {
                input {
                    padding: 8px 15px 8px 25px;
                }
                i{
                    top: 0;
                    font-size: 16px;
                }
                img{
                    height: 13px;
                }
            }
        }
    }
    
    section,
    .section-t-space {
        padding-top: 60px;
    }
    .section-b-space {
        padding-bottom: 60px;
    }
    .medium-section {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .small-section {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .breadcrumb-section {
        &.breadcrumb-cum-header {
            height: 530px;
            .breadcrumb-content {
                height: 530px;
                p {
                    max-width: 88%;
                }
            }
        }
        .breadcrumb-content {
            &.restaurant-name {
                h3 {
                    margin-bottom: 4px;
                }
                h6 {
                    margin-bottom: 8px;
                }
            }
        }
    }
    .position-cls {
        &.b-top {
            background-position: right !important;
        }
    }
    .font-xs-white {
        color: white;
    }
    h2 {
        line-height: 26px;
    }
    .title-1 {
        h2 {
            padding-top: 10px;
            padding-bottom: 30px;
        }
        &.title-5 {
            h2 {
                padding-top: 10px;
                padding-bottom: 5px;
            }
            p {
                margin-bottom: 30px;
            }
        }
        &.detail-title {
            p {
                max-width: 95%;
            }
        }
    }
    .title-2 {
        h2 {
            margin-bottom: 18px;
        }
        p {
            margin-bottom: 50px;
        }
    }
    .title-3 {
        h2 {
            margin-top: 15px;
            margin-bottom: 10px;
        }
        &.detail-title {
            p {
                max-width: 95%;
            }
        }
    }
    .form-icon {
        .form-group {
            .form-control {
                padding: 10px 10px 10px 30px !important;
            }
            .icon {
                left: 10px;
                font-size: 14px;
            }
        }
    }
    .home_section {
        .home {
            height: 80vh;
            .home-content {
                height: 80vh;
                h1 {
                    margin-top: -8px;
                }
                h5 {
                    letter-spacing: 0.1em;
                    margin-bottom: 10px;
                }
                h2 {
                    margin-bottom: 10px;
                    letter-spacing: 0.1em;
                }
                >div {
                    padding: 30px 90px;
                }
                .flight-search {
                    .flight-search-detail {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        display: block;
                        background-color: white;
                        padding: 20px 0;
                        form {
                            width: 100%;
                            padding: 0;
                            .col {
                                -webkit-box-flex: 0;
                                -ms-flex: 0 0 50%;
                                flex: 0 0 50%;
                                max-width: 50%;
                                &.search-col {
                                    flex: unset;
                                    max-width: initial;
                                    margin-left: 66.66667%;
                                }
                            }
                        }
                    }
                }
                &.food_content {
                    .book-table {
                        .table-form {
                            padding: 15px;
                        }
                    }
                }
            }
            &.home-70 {
                height: 60vh;
                .home-content {
                    height: 60vh;
                }
            }
            &.home-long {
                background-blend-mode: overlay;
                background-color: rgba(0, 0, 0, 0.4);
                .home-content {
                    align-items: center;
                    &.mix-layout {
                        .flight-search {
                            margin-left: -15px;
                            margin-right: -15px;
                            .flight-search-detail {
                                background-color: transparent;
                                form {
                                    background-color: transparent;
                                    >div {
                                        padding-left: 15px !important;
                                        padding-right: 15px !important;
                                    }
                                }
                                .form-group {
                                    .form-control {
                                        padding: 10px 10px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .banner-content {
                h4,
                h1 {
                    color: white;
                }
            }
        }
        .view-icon {
            img {
                width: 70%;
            }
        }
        .slick-prev,
        .slick-next {
            padding: 20px;
            &:after {
                font-size: 16px;
            }
        }
    }
    .tour_layout {
        .flight-search {
            .flight-search-detail {
                .form-group {
                    .gj-datepicker,
                    .gj-timepicker {
                        button {
                            width: 46px;
                            i {
                                top: 12px;
                                left: 12px;
                            }
                        }
                    }
                    img {
                        padding: 12px;
                        bottom: 1px;
                    }
                    .form-control {
                        border-right: none;
                    }
                }
            }
        }
    }
    .a-enter-vr-button {
        min-width: 55px !important;
        min-height: 55px !important;
    }
    .cab-section {
        .cab-content {
            >div {
                background-color: rgba(black, 0.2);
                padding: 25px;
                text-align: center;
            }
            .car-select {
                .btn {
                    width: 135px;
                }
            }
        }
    }
    .deals {
        .deals-box {
            .left-portion {
                flex: 0 0 100%;
                max-width: 100%;
                &:before,
                &:after {
                    display: none;
                }
                .deals-content {
                    .detail {
                        position: relative;
                        top: 0;
                        transform: none;
                        left: 0;
                        writing-mode: unset;
                        padding: 12px;
                        align-items: center;
                    }
                    img {
                        display: none;
                    }
                }
            }
            .deals-img {
                -webkit-box-flex: 0;
                -ms-flex: 0 0 100%;
                flex: 0 0 100%;
                max-width: 100%;
                order: -1;
            }
        }
    }
    .arrow-classic {
        .slick-prev,
        .slick-next {
            display: none !important;
            &:before {
                padding: 6px;
                font-size: 14px;
                border-radius: 4px;
            }
        }
        .slick-prev {
            left: -50px;
        }
        .slick-next {
            right: -45px;
        }
    }
    .app-section {
        .order-cls {
            >div {
                &:first-child {
                    order: 2;
                    margin-top: 30px;
                }
            }
        }
    }
    .app-section {
        .app-box {
            background-color: rgba(white, 0.05);
        }
    }
    .price-section {
        .container-fluid {
            padding-left: 50px;
            padding-right: 50px;
        }
    }
    .service-section {
        >div {
            margin-bottom: 30px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .service-box {
            padding: 40px;
        }
    }
    .service_section {
        >div {
            &:nth-last-child(-n + 4) {
                margin-top: 20px;
            }
        }
    }
    .service-part {
        >div {
            margin-top: 30px;
            &:first-child {
                margin-top: 0;
            }
        }
        .service-wrapper {
            padding: 40px;
        }
    }
    .search-section {
        .search-box {
            .left-part {
                .search-body {
                    .form-control {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    .testimonial-section {
        .testimonial {
            padding-bottom: 70px;
            .left-part {
                img {
                    width: 100px;
                    height: 100px;
                }
                .design {
                    right: -35px;
                    width: 50px;
                    height: 50px;
                    i {
                        font-size: 20px;
                    }
                }
            }
            .quote-icon {
                right: 30px;
                i {
                    font-size: 70px;
                }
            }
        }
    }
    .testimonial_section {
        .testimonial {
            .top-part {
                .img-part {
                    margin-bottom: 35px;
                    margin-top: 50px;
                    img {
                        width: 100px;
                        height: 100px;
                    }
                    .heart-icon {
                        padding: 8px;
                        font-size: 14px;
                    }
                }
            }
            .bottom-part {
                p {
                    margin-bottom: 25px;
                }
                .quote-icon {
                    i {
                        font-size: 60px;
                    }
                }
            }
        }
    }
    .subscribe_section {
        .subscribe-detail {
            text-align: center;
            padding-right: 0;
            padding-bottom: 30px;
            h2 {
                margin-bottom: 5px;
            }
            &:after {
                display: none;
            }
        }
        .input-section {
            padding-left: 0;
        }
    }
    .about-section {
        .about-text {
            padding-left: 0;
            margin-top: 30px;
            height: auto;
        }
    }
    .ticket-section {
        .ticket-box {
            .content {
                position: relative;
                top: 0;
                transform: none;
                left: 0;
                width: 80%;
                margin: -50px auto 0 auto;
                .detail {
                    max-width: 435px;
                }
                .barcode-design {
                    img {
                        height: 230px;
                    }
                }
            }
            .ticket-title {
                top: 33%;
                z-index: 1;
                right: -14%;
            }
        }
        .slick-next,
        .slick-prev {
            right: unset;
            left: 10px;
            &:before {
                padding: 4px;
            }
        }
        .slick-next {
            top: calc(33% - 20px);
        }
        .slick-prev {
            top: calc(33% + 20px);
        }
        &.white-section {
            .ticket-box {
                margin-bottom: 45px;
            }
            &.section-b-space {
                padding-bottom: 25px;
            }
        }
    }
    .video-section {
        padding: 80px;
    }
    .video_section {
        padding: 80px 0;
        .order-cls {
            order: -1;
            margin-bottom: 40px;
        }
        .video-image {
            width: 80%;
            margin: 0 auto;
        }
        .video-content {
            justify-content: center;
            text-align: center;
            p {
                padding-right: 0;
            }
            .bottom-section {
                justify-content: center;
            }
        }
        .offer-text {
            display: block;
        }
    }
    .gallery-section {
        .zoom-gallery {
            >div {
                &:nth-child(1) {
                    flex: 0 0 71.42%;
                    max-width: 71.42%;
                }
                &:nth-child(2) {
                    flex: 0 0 28.58%;
                    max-width: 28.58%;
                }
                &:nth-child(3) {
                    flex: 0 0 60%;
                    max-width: 60%;
                }
                &:nth-child(4) {
                    flex: 0 0 40%;
                    max-width: 40%;
                }
            }
        }
    }
    .blog-section {
        .blog-box {
            .img-part {
                img {
                    min-width: 230px;
                }
            }
            .blog-content {
                padding: 45px;
                p {
                    line-height: 1.3;
                    margin-bottom: 10px;
                }
                h5 {
                    margin-bottom: 10px;
                }
                h6 {
                    margin-bottom: 14px;
                }
            }
        }
    }
    .blog_section {
        &.section-b-space {
            padding-bottom: 40px;
        }
        .slick-slide {
            >div {
                margin: 0 20px;
            }
        }
        .slick-list {
            margin-left: -20px;
            margin-right: -20px;
        }
        .blog-wrap {
            border-radius: 18px;
            .blog-details {
                padding: 25px 25px;
                h6 {
                    margin-bottom: 12px;
                }
                h5 {
                    margin-bottom: 8px;
                }
            }
            .blog-image {
                .blog-label {
                    padding: 7px 8px;
                    left: 20px;
                    bottom: 20px;
                }
            }
        }
        &.destination-section {
            .destination-details {
                padding: 30px;
                h6 {
                    line-height: 1.4;
                    margin-bottom: 15px;
                }
            }
        }
    }
    .special-section {
        .slick-slide {
            >div {
                margin: 0 15px;
            }
        }
        .slick-list {
            margin-left: -15px;
            margin-right: -15px;
        }
        .special-box {
            .special-content {
                padding: 25px;
                h5 {
                    line-height: 1.3;
                    margin-bottom: 10px;
                    margin-top: -3px;
                }
            }
        }
        &.section-b-space {
            padding-bottom: 40px;
        }
    }
    .full-banner {
        padding: 70px 0;
        .banner-content {
            h5 {
                margin-bottom: 15px;
            }
            h2 {
                margin-bottom: 15px;
            }
            p {
                line-height: 1.3;
                margin-bottom: 10px;
            }
        }
        &.banner-section {
            .banner-content {
                margin-top: 30px;
                align-items: flex-start;
            }
        }
    }
    .category-section {
        .slick-slide {
            >div {
                margin: 0 15px;
            }
        }
        .slick-list {
            margin-left: -15px;
            margin-right: -15px;
        }
    }
    .category-sec {
        .category-box {
            .img-category {
                .top-bar {
                    top: 18px;
                    left: 18px;
                    .offer {
                        margin-bottom: 8px;
                    }
                }
                .like-cls {
                    i {
                        font-size: 18px;
                        padding: 10px;
                    }
                }
            }
            .content-category {
                padding: 55px 25px 20px 25px;
            }
        }
        &.section-b-space {
            padding-bottom: 30px;
        }
    }
    .category-wrapper {
        &.section-b-space {
            padding-bottom: 40px;
        }
        .title-1 {
            &.title-5 {
                p {
                    margin-bottom: 0;
                }
            }
        }
    }
    .category-bg {
        &.section-b-space {
            padding-bottom: 60px;
        }
    }
    .detail-section {
        .detail-box {
            padding: 0;
            .upper-part {
                margin-bottom: 15px;
            }
        }
    }
    .routes-section {
        .row {
            >div {
                &:nth-child(-n + 2) {
                    margin-bottom: 30px;
                }
            }
        }
    }
    .flight-detail {
        .flight-box {
            .logo-section {
                width: 150px;
            }
            .middle-section {
                width: 59%;
                .flight-info {
                    padding: 25px 0;
                }
            }
            .name-section {
                width: 140px;
            }
        }
    }
    .about_section {
        .about_content {
            margin-top: 30px;
            align-items: flex-start;
            margin-left: 0;
            h5 {
                margin-bottom: 5px;
            }
            h2 {
                margin-bottom: 5px;
            }
            p {
                margin-bottom: 20px;
            }
        }
    }
    .filter-section {
        .top-section {
            margin-bottom: 40px;
        }
        .content {
            .grid-item {
                &:nth-child(-n + 3) {
                    margin-bottom: 30px;
                }
            }
        }
    }
    .food-banner {
        padding: 110px 0;
        .food-content {
            .strip-cls {
                margin-top: 30px;
                .timer {
                    li {
                        margin: 0 10px;
                        &:after {
                            left: 38px;
                        }
                    }
                }
            }
            .buttons {
                margin-top: 20px;
            }
            h3 {
                margin-top: -3px;
            }
        }
    }
    .discount-banner {
        padding: 100px 0;
    }
    .book-table {
        .table-form {
            padding: 20px;
            &:before,
            &:after {
                border-radius: 14px;
            }
        }
        &.single-table {
            .table-form {
                margin-top: -50px;
            }
        }
    }
    .facts-section {
        .row {
            >div {
                &:nth-last-child(-n + 2) {
                    margin-top: 30px;
                }
            }
        }
    }
    .process-steps {
        .step-bg {
            .row {
                margin-left: -15px;
                margin-right: -15px;
                >div {
                    padding-left: 15px;
                    padding-right: 15px;
                }
                &.popular-section {
                    >div {
                        &:nth-child(2),
                        &:nth-child(4) {
                            margin-top: 0;
                        }
                    }
                }
            }
            .step-box {
                padding: 20px 15px;
            }
        }
    }
    .cab-full {
        height: auto;
        iframe {
            height: 40vh;
        }
        .left-bar {
            height: 100%;
            &.fixed-cls {
                .flight-search {
                    .flight-search-detail {
                        form {
                            margin-right: -15px;
                            margin-left: -15px;
                            >div {
                                margin-top: 20px;
                                padding-left: 15px;
                                padding-right: 15px;
                            }
                        }
                    }
                }
            }
        }
        .flight-search {
            .flight-search-detail {
                position: relative;
                width: 100%;
                height: 100%;
                display: block;
                background-color: white;
                padding: 15px;
                z-index: 1;
                form {
                    padding: 0;
                    width: 100%;
                    margin: 0;
                    >div {
                        padding: 0;
                        margin-top: 0;
                    }
                }
            }
        }
        .location-option {
            display: flex !important;
            .form-check {
                +.form-check {
                    margin-right: 5px;
                }
            }
        }
    }
    .home-section {
        height: 100%;
        .flight-left {
            display: none;
        }
    }
    .top-banner {
        .top-banner-content {
            right: 15%;
            transform: translateY(-50%) scale(0.7);
        }
    }
    // inner pages
    .res-cab {
        .row {
            >div {
                -webkit-box-flex: 0;
                -ms-flex: 0 0 100%;
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
    }
    .mobile-filter {
        display: flex;
        border-top: 1px solid $inner-bg;
        justify-content: space-between;
        &.filter_button {
            display: none;
        }
    }
    .left-sidebar {
        position: fixed;
        width: 300px;
        left: -300px;
        top: 0;
        margin-top: 0;
        z-index: 5;
        overflow-y: auto;
        height: 100vh;
        box-shadow: 0 15px 5px 0 rgba(77, 77, 77, 0.28);
        .back-btn {
            display: block;
        }
        .search-bar {
            display: none;
        }
    }
    .mobile-filter {
        padding: 20px 25px;
    }
    .search-panel {
        .search-section {
            .search-box {
                .left-part {
                    .search-body {
                        h6 {
                        }
                    }
                }
            }
        }
    }
    .map-section {
        margin-top: 0;
        iframe,
        .map {
            height: 350px;
            position: relative;
            margin-top: 30px;
        }
    }
    .map-modal {
        .modal-dialog {
            .modal-content {
                .modal-body {
                    .left-sidebar {
                        display: none;
                    }
                    .container-fluid {
                        padding: 0;
                    }
                }
            }
        }
        .map-section {
            .map {
                margin-top: 0;
                height: 350px !important;
            }
        }
    }
    .mobile-map {
        display: block;
    }
    .desktop-map {
        display: none;
    }
    .view-map {
        &.mobile-map {
            display: block;
        }
    }
    .onclick-map {
        &.show {
            display: block !important;
        }
        iframe {
            margin-top: 10px;
        }
        >div {
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }
    .list-view {
        margin-top: 30px;
        .list-box {
            .list-content {
                margin-left: 15px;
                .book-now {
                    padding: 5px 9px;
                }
                .offer-box {
                    padding: 7px;
                    max-width: 130px;
                    font-size: 12px;
                    margin-top: 3px;
                }
                .facility-icon {
                    margin-top: 14px;
                }
                .price {
                    margin-top: 10px;
                }
                p {
                    margin-bottom: 5px;
                }
            }
        }
    }
    .single-section {
        .description-section {
            .menu-top {
                li {
                    &.mobile-menu {
                        display: inline-block;
                    }
                    a {
                        padding: 11px;
                    }
                }
                &.sticky {
                    padding: 12px 0;
                    li {
                        a {
                            padding: 11px;
                        }
                    }
                }
                &.menu-up {
                    margin-bottom: 20px;
                }
            }
            .description-details {
                .menu-part {
                    .zoom-gallery {
                        >div {
                            &:nth-child(-n + 4) {
                                margin-bottom: 20px;
                            }
                        }
                    }
                }
            }
        }
        .single-sidebar {
            margin-top: 30px;
            .weather-sec {
                justify-content: flex-start;
            }
            .overlay-map {
                img {
                    height: auto;
                }
            }
            &.order-cart-right {
                position: fixed;
                right: -300px;
                height: 100vh;
                top: 0;
                margin-top: 0;
                background-color: white;
                z-index: 9;
                padding: 20px 30px;
                transition: all 0.5s ease;
                overflow-y: scroll;
                box-shadow: 0 15px 5px 0 rgba(77, 77, 77, 0.28);
                width: 300px;
                .back-btn {
                    display: block;
                }
            }
        }
        .order-class {
            order: 2;
        }
    }
    .cart {
        display: flex;
    }
    .variable-width {
        .slick-slide {
            img {
                height: 400px;
            }
        }
    }
    .booking-order {
        order: -1;
        margin-bottom: 30px;
    }
    .book-table {
        .table-form {
            form {
               ::placeholder {
                font-size: 14px;
            }
        }
    }
}
.detail-menu {
    .nav-tabs {
        .nav-item {
            margin-right: 0;
        }
    }
}
.full-width-detail {
    .place-detail {
        li {
            padding: 0 14px;
        }
    }
}
.order_cls {
    order: 1;
}
.order-menu-section {
    border-left: none;
    .order-section {
        .order-items {
            .items {
                p {
                    max-width: 80%;
                }
            }
        }
    }
}
.flight-search {
    .flight-search-detail {
        position: fixed;
        background-color: rgba(black, 0.65);
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        display: none;
        left: 0;
        top: 0;
        z-index: 9;
        align-items: center;
        justify-content: center;
        padding: 0;
        form {
            padding: 30px 20px;
            background-color: white;
            width: 70%;
            position: relative;
            animation: zoom_in 0.1s ease;
            transition: all 0.5s ease;
            .responsive-close {
                display: block;
                position: absolute;
                right: -28px;
                top: -15px;
                i {
                    color: white;
                    font-size: 20px;
                }
            }
        }
        .form-group {
            margin-bottom: 12px;
        }
        &.show {
            display: flex;
            form {
                transition: all 0.5s ease;
            }
        }
    }
    .responsive-detail {
        display: block;
    }
}
.top-bar-flight {
    .fare-calender {
        .calender-external {
            .fc th,
            .fc td {
                width: 60px;
            }
            .fc-dayGrid-view .fc-body .fc-row {
                height: 50px !important;
                min-height: 50px;
            }
            .fc-toolbar h2 {
                font-size: 20px;
            }
            .fc-toolbar.fc-header-toolbar {
                margin-bottom: 20px;
            }
            .fc-button {
                padding: 5px;
            }
        }
    }
}
.top-filter-section {
    position: fixed;
    left: -300px;
    width: 300px;
    height: 100vh;
    top: 0;
    margin-top: 0;
    padding: 20px;
    z-index: 5;
    transition: all 0.5s ease;
    box-shadow: 0 15px 5px 0 rgba(77, 77, 77, 0.28);
    overflow-y: auto;
    ul {
        display: block;
        .back-btn {
            padding-bottom: 20px;
            border-bottom: 1px solid $inner-bg;
            font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
            color: black;
            font-weight: 700;
            margin-bottom: 0;
            cursor: pointer;
            display: block;
        }
        .filter-title {
            display: none;
        }
        li {
            display: block;
        }
        .onclick-title,
        .filter-title {
            border-left: none;
            border-top: 1px solid $inner-bg;
            h6 {
                text-align: left;
                padding: 14px 0 0;
                font-size: 14px;
                font-weight: 600;
                &:before {
                    display: none;
                }
            }
            .onclick-content {
                display: block;
                position: relative;
                width: 100%;
                left: 0;
                top: 0;
                box-shadow: none;
                padding: 10px 0;
            }
        }
    }
}
.book_panel {
    &.flight-detail-sec {
        .detail-bar {
            .detail-wrap {
                .airport-part {
                    .airport-name {
                        h4 {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
.flight-detail-sec {
    &.round_trip {
        .row {
            >div {
                &:nth-child(2) {
                    .selection-bar {
                        margin-top: 30px;
                    }
                }
            }
        }
    }
}
.res-margin {
    margin-top: 20px;
}
.success-section {
    .success-detail {
        p {
            max-width: 100%;
        }
    }
}
.view-map {
    margin-right: 0;
}
.filter-bottom-content {
    left: -300px;
    display: block;
    .middle-part {
        .collection-collapse-block-content {
            &.row {
                padding-top: 40px;
            }
            .filter-block {
                flex: 0 0 100%;
            }
            .close-filter-bottom {
                position: absolute;
                right: 15px;
                top: 15px;
                background-color: transparent;
                color: $theme-color1;
                box-shadow: none;
                &:hover {
                    box-shadow: none;
                    border: none;
                }
            }
        }
    }
    &.open {
        left: -1px;
    }
}
.filter-bottom-title {
    padding-left: 15px;
}
.single-section {
    .single-sidebar {
        .selection-section {
            &.flight-search {
                .flight-search-detail {
                    position: relative;
                    display: block;
                    width: 100%;
                    height: 100%;
                    background-color: white;
                    z-index: 1;
                    form {
                        width: 100%;
                        padding: 0;
                    }
                }
            }
        }
    }
}
.portfolio-creative {
    .content-sec {
        margin-top: 15px;
        display: block;
    }
    .row {
        padding-top: 30px;
        &:first-child {
            padding-top: 0;
        }
    }
    .order-section {
        div {
            order: 1;
            &:nth-child(2) {
                order: 4;
                margin-bottom: 30px;
            }
            &:nth-child(3) {
                order: 3;
            }
            &:nth-child(4) {
                order: 2;
                margin-bottom: 30px;
            }
            &:nth-child(5) {
                order: 5;
            }
            &:nth-child(6) {
                order: 6;
            }
        }
    }
}
.blog-sidebar {
    margin-top: 30px;
    .blog-wrapper {
        .sidebar-content {
            .blog-post {
                li {
                    width: 49%;
                }
            }
        }
    }
}
.blog-inner {
    &.blog_section {
        margin-top: 30px;
        .blog-list {
            .blog-wrap {
                .blog-image {
                    width: 50%;
                }
            }
        }
    }
}
.contact_section {
    .row {
        >div {
            &:nth-child(-n + 2) {
                margin-bottom: 25px;
            }
        }
    }
}
.faq-tab {
    .nav-tabs {
        .nav-item {
            display: inline-block;
            .nav-link {
                &.active {
                    border-right: none;
                    border-bottom: 2px solid $theme-color1;
                }
            }
        }
    }
}
.faq-content {
    margin-top: 20px;
}
.dashboard-section {
    .dashboard-box {
        .dashboard-detail {
            .card-payment {
                .payment-card {
                    &.add-card {
                        .card-details {
                            min-height: 148px;
                        }
                    }
                }
            }
        }
    }
    .faq-content {
        min-height: auto;
    }
    .dashboard-sidebar {
        padding: 20px 0 0 0;
        .faq-tab {
            .nav-tabs {
                text-align: center;
            }
        }
        .profile-top {
            .profile-image {
                .profile-edit {
                    right: 41%;
                }
            }
        }
    }
}
.hotel-single-section {
    .hotel-title-section {
        .hotel-name {
            .left-part {
                .top {
                    .share-buttons {
                        .btn {
                            font-size: 0;
                            padding: 6px 10px;
                            line-height: 1;
                            i {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}
.hotel_title_section {
    .hotel-name {
        .left-part {
            .top {
                .share-buttons {
                    .btn {
                        font-size: 0;
                        padding: 6px 10px;
                        line-height: 1;
                        i {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}
.checkout-cart {
    order: -1;
    margin-bottom: 30px;
    .single-sidebar {
        &.order-cart-right {
            position: relative;
            right: 0;
            height: auto;
            width: 100%;
            box-shadow: 0 2px 4px 0 #dedede;
        }
    }
}
.skeleton_loader {
    .hotel_title_section {
        .hotel-name {
            .left-part {
                .top {
                    .share-buttons {
                        .ldr-btn {
                            width: 28px;
                            height: 24px;
                        }
                    }
                }
            }
        }
    }
}
}

@media (max-width: 767px) {
    section,
    .section-t-space {
        padding-top: 50px;
    }
    .section-b-space {
        padding-bottom: 50px;
    }
    .breadcrumb-section {
        height: 350px;
        .breadcrumb-content {
            height: 350px;
            padding-top: 84px;
            >div {
                padding: 30px 45px;
            }
        }
        .title-breadcrumb {
            font-size: 370px;
        }
        &.parallax-img {
            background-attachment: unset;
        }
        &.breadcrumb-cum-header {
            height: 400px;
            .breadcrumb-content {
                height: 400px;
                p {
                    display: none;
                }
                h2 {
                    margin-bottom: 10px;
                }
            }
        }
    }
    .medium-section {
        padding-top: 35px;
        padding-bottom: 35px;
    }
    .small-section {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .title-1 {
        h2 {
            padding-bottom: 20px;
        }
    }
    .title-2 {
        h2 {
            margin-bottom: 10px;
            margin-top: -4px;
        }
        p {
            margin-bottom: 30px;
        }
    }
    .title-3 {
        h2 {
            margin-bottom: 30px;
        }
    }
    .home_effect {
        .effect_image {
            img {
                bottom: -70px;
            }
        }
    }
    .partition2 {
        >div {
            &:first-child {
                margin-bottom: 30px;
            }
        }
    }
    .circle {
        height: 120px;
        width: 120px;
        &.b-round {
            height: 180px;
            width: 180px;
        }
    }
    header {
        .menu {
            .header-right {
                .setting {
                    display: block;
                }
                .front-setting {
                    select {
                        background: url(../../images/icon/down-black.png) no-repeat 80%;
                    }
                }
                .user {
                    margin-left: 0;
                }
            }
        }
        .mix-pills {
            .nav-item {
                margin-right: 7px;
            }
        }
    }
    .cab-section {
        .cab-content {
            padding-top: 74px;
            form {
                .row {
                    >div {
                        -webkit-box-flex: 0;
                        -ms-flex: 0 0 100%;
                        flex: 0 0 100%;
                        max-width: 100%;
                        margin-top: 25px;
                        &:first-child {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
        &.flight-section {
            .cab-content {
                padding-top: 90px;
            }
        }
    }
    .home_section {
        .home {
            height: 70vh;
            .home-content {
                height: 70vh;
                padding-top: 73px;
                h1 {
                    margin-top: -6px;
                    margin-bottom: 3px;
                }
                .flight-search {
                    .flight-search-detail {
                        form {
                            .col {
                                &.search-col {
                                    margin-left: 60%;
                                }
                            }
                        }
                    }
                }
                &.food_content {
                    >div {
                        background-color: unset;
                        padding: 30px 90px;
                        background-color: rgba(black, 0.2);
                    }
                    .book-table {
                        .table-form {
                            form {
                                .btn-rounded {
                                    margin-top: 15px;
                                }
                            }
                        }
                    }
                }
                &.mix-layout {
                    &.smaller-content {
                        .search-panel {
                            .search-section {
                                .search-box {
                                    .left-part {
                                        .search-body {
                                            padding: 10px 10px;
                                            &:nth-child(3),
                                            &:nth-child(5) {
                                                &:after {
                                                    display: none;
                                                }
                                            }
                                            &:nth-child(4) {
                                                &:after {
                                                    display: block;
                                                    left: -5px;
                                                    right: unset;
                                                }
                                            }
                                            &.btn-search {
                                                &:after {
                                                    display: none;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .smoke-effect {
                display: none;
            }
            &.home-padding {
                .home-content {
                    padding: 50px 0 50px 0;
                }
            }
            &.home-mobile {
                height: 80vh;
                .home-content {
                    &.smaller-content {
                        height: 80vh;
                    }
                }
            }
            &.home-60 {
                height: 50vh;
                .home-content {
                    height: 50vh;
                }
            }
            &.home-70 {
                height: 55vh;
                .home-content {
                    height: 55vh;
                }
            }
            &.home-long {
                background-blend-mode: overlay;
                background-color: rgba(0, 0, 0, 0.3);
                .home-content {
                    align-items: center;
                    padding-bottom: 0;
                }
            }
        }
        .offer-text {
            top: 46%;
            left: -78px;
            h6 {
                letter-spacing: 0.02em;
            }
        }
    }
    .top-category {
        padding: 0 15px;
        .slick-slide {
            >div {
                margin: 0 15px;
            }
        }
        .slick-list {
            margin-left: -15px;
            margin-right: -15px;
        }
        &.margin-cls {
            margin-top: -40px;
        }
        .top_box {
            .right-content {
                padding: 0px 15px;
                .rating {
                    display: none;
                }
            }
        }
    }
    .app-section {
        .app-image {
            .image {
                &:last-child {
                    margin-left: 30px;
                }
            }
        }
        .app-content {
            .app-buttons {
                a {
                    +a {
                        margin-left: 10px;
                    }
                }
            }
            .title {
                margin-bottom: 15px;
                span {
                    display: unset;
                }
            }
            p {
                margin-bottom: 15px;
            }
            h3 {
                margin-bottom: 15px;
            }
            .label {
                margin-bottom: 20px;
            }
        }
        .app-box {
            text-align: center;
            background-color: rgba(white, 0.1);
            h2,
            h5 {
                margin-bottom: 15px;
            }
            p {
                margin-bottom: 18px;
            }
        }
        &.app-right-sec {
            .app-image {
                display: none !important;
            }
            .app-content {
                text-align: center;
                .title {
                    margin-top: -3px;
                }
            }
        }
    }
    .service_section {
        .service-wrap {
            padding: 10px 0;
        }
    }
    .testimonial-section {
        .testimonial {
            padding-bottom: 50px;
            .left-part {
                .design {
                    right: -25px;
                    width: 40px;
                    height: 40px;
                }
            }
            .right-part {
                margin-left: 9%;
            }
        }
        .slick-prev,
        .slick-next {
            display: none !important;
        }
    }
    .subscribe-footer {
        .service-left {
            .service-box {
                display: block;
                text-align: center;
                padding: 0px 12px;
            }
        }
    }
    .subscribe_section {
        .subscribe-detail {
            padding-bottom: 15px;
        }
    }
    .about-section {
        .about-text {
            h3,
            h2 {
                margin-bottom: 10px;
            }
            p {
                margin-bottom: 7px;
            }
        }
        &.three-image {
            .image-section {
                .img-box {
                    +.img-box {
                        margin-left: 25px;
                    }
                }
            }
            .about-text {
                h3,
                h5,
                p {
                    margin-bottom: 10px;
                }
                h2 {
                    margin-bottom: 5px;
                }
            }
        }
    }
    .ticket-section {
        .ticket-box {
            .content {
                width: 85%;
            }
            .ticket-title {
                position: relative;
                top: 0;
                right: 0;
                left: 0;
                transform: rotate(0deg) translate(0);
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: -20px;
                span {
                    padding: 12px;
                }
            }
            .image-box {
                box-shadow: none;
            }
        }
        .slick-next {
            top: 34%;
            right: 0;
            left: unset;
        }
        .slick-prev {
            top: 34%;
            left: -10px;
            right: unset;
        }
        &.white-section {
            &.section-b-space {
                padding-bottom: 5px;
            }
        }
    }
    .video-icon {
        width: 70px;
        height: 45px;
        border-radius: 10px;
    }
    .video-section {
        padding: 70px;
        .basic-section {
            .video-icon {
                .animation-circle-inverse {
                    i {
                        border-radius: 10px;
                    }
                }
            }
            h4 {
                letter-spacing: 0.3em;
            }
        }
    }
    .video_section {
        padding: 70px 0;
    }
    .menu-section {
        .menu-box {
            .bottom-bar {
                .menu-bar {
                    .content {
                        h6 {
                            label {
                                right: -20px;
                            }
                        }
                    }
                }
            }
        }
    }
    .blog-section {
        .blog-box {
            .blog-content {
                padding: 15px;
            }
        }
    }
    .blog_section {
        &.section-b-space {
            padding-bottom: 20px;
        }
    }
    .blog_section {
        .blog-wrap {
            .blog-details {
                padding: 30px;
            }
            .blog-image {
                .blog-label {
                    padding: 10px 12px;
                }
            }
        }
    }
    .special-section {
        .special-box {
            box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.05);
            .special-img {
                .content-inner {
                    width: 75%;
                }
            }
            &:hover {
                box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.1);
            }
        }
        &.grid-box {
            .special-box {
                .special-content {
                    &.restaurant-detail {
                        h5 {
                            span {
                                font-size: 12px;
                                margin-left: 5px;
                            }
                        }
                    }
                }
            }
        }
        &.section-b-space {
            padding-bottom: 20px;
        }
    }
    .full-banner {
        padding: 60px 0;
        .banner-content {
            >div {
                padding: 30px;
                background-color: rgba(black, 0.36);
            }
        }
        &.banner-section {
            .banner-content {
                >div {
                    padding: 0;
                    background-color: transparent;
                }
            }
            .place-image {
                .animation-place {
                    .plane-l {
                        display: none;
                    }
                    .plane-r {
                        right: 2%;
                        top: 7%;
                        img {
                            width: 60%;
                        }
                    }
                    .cloud-l {
                        top: 6%;
                        left: 4%;
                        img {
                            width: 60%;
                        }
                    }
                    .cloud-r {
                        top: 5%;
                        img {
                            width: 70%;
                        }
                    }
                }
            }
        }
    }
    .category-sec {
        &.section-b-space {
            padding-bottom: 10px;
        }
    }
    .category-wrapper {
        .category-wrap {
            .category-content {
                p {
                    margin-bottom: 10px;
                }
                .top {
                    margin-bottom: 10px;
                    h6 {
                        margin-left: 15px;
                    }
                }
                .rating {
                    margin-bottom: 10px;
                }
                .bottom {
                    .coupon-code {
                        padding: 7px 8px;
                    }
                }
            }
        }
        &.section-b-space {
            padding-bottom: 20px;
        }
    }
    .category-bg {
        .slick-slide {
            >div {
                margin: 0 10px;
            }
        }
        .slick-list {
            margin-left: -10px;
            margin-right: -10px;
        }
        &.section-b-space {
            padding-bottom: 40px;
        }
    }
    .detail-section {
        .detail-box {
            .upper-part {
                min-height: auto;
                &:after {
                    display: none;
                }
            }
        }
        .row {
            >div {
                margin-bottom: 30px;
                &:last-child {
                    margin-bottom: 0;
                    .detail-box {
                        padding-bottom: 0;
                        &:after {
                            display: none;
                        }
                    }
                }
                .detail-box {
                    padding-bottom: 30px;
                    &:after {
                        content: "";
                        position: absolute;
                        width: 70%;
                        height: 1px;
                        background-color: rgba(255, 255, 255, 0.23);
                        bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
            }
        }
    }
    .flight-detail {
        .flight-box {
            .middle-section {
                width: 50%;
                .flight-info {
                    padding: 20px 0;
                    h4 {
                        margin-bottom: 5px;
                    }
                    h5 {
                        margin-bottom: 7px;
                    }
                    i {
                        font-size: 12px;
                        padding: 5px;
                    }
                }
            }
            .logo-section {
                width: 130px;
                img {
                    width: 50%;
                }
            }
            .name-section {
                width: 130px;
                h5 {
                    margin-bottom: 5px;
                }
                h6 {
                    margin-bottom: 8px;
                    letter-spacing: 0.3em;
                }
                .btn {
                    padding: 3px 10px;
                }
            }
        }
        .row {
            >div {
                .flight-box {
                    +.flight-box {
                        margin-top: 20px;
                    }
                }
            }
        }
    }
    .filter-section {
        background-image: none;
        background-color: $theme-color1;
        .top-section {
            margin-bottom: 50px;
            align-items: flex-start;
            .coupon {
                margin-left: auto;
                margin-right: 0;
            }
        }
        .filters {
            position: absolute;
            right: 15px;
            bottom: 20px;
        }
        .content {
            margin-left: -15px;
            margin-right: -15px;
            .grid-item {
                padding: 0 15px;
                &:nth-child(-n + 4) {
                    margin-bottom: 30px;
                }
                .filter-detail {
                    opacity: 1;
                }
            }
        }
    }
    .food-banner {
        padding: 90px 0;
        .food-content {
            text-align: center;
            h3 {
                margin-bottom: 35px;
                margin-top: -2px;
            }
            .strip-cls {
                margin-top: 10px;
            }
        }
    }
    .discount-banner {
        background-position: left;
        .banner-content {
            text-align: center;
            background-color: rgba($theme-color1, 0.65);
            padding: 25px;
        }
    }
    .book-table {
        background-position: left;
        background-color: #c12525;
        .table-form {
            padding: 25px;
            form {
                display: block;
                .row {
                    display: block;
                    margin-right: 0;
                    margin-left: 0;
                    >div {
                        +div {
                            margin-top: 15px;
                        }
                    }
                }
                .btn-rounded {
                    margin-left: 0;
                    margin-top: 25px;
                    width: 100%;
                }
                .form-group {
                    margin-right: 0;
                }
                .gj-datepicker {
                    margin-right: 0;
                }
            }
        }
        &.single-table {
            background-color: transparent;
        }
    }
    .full-page {
        height: 130vh;
        overflow: scroll;
        .single-section {
            height: 130vh;
            .description-section {
                .description-details {
                    .menu-part {
                        .list-view {
                            .list-box {
                                text-align: center;
                                .list-img {
                                    width: 70%;
                                    margin: 0 auto;
                                }
                                .list-content {
                                    .facility-icon {
                                        justify-content: center;
                                    }
                                    .rating {
                                        justify-content: center;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .cab-full {
        .left-bar {
            &.fixed-cls {
                .flight-search {
                    .flight-search-detail {
                        form {
                            margin: 0;
                            >div {
                                margin-top: 0;
                                padding: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    .top-banner {
        .top-banner-content {
            right: 5%;
            transform: translateY(-50%) scale(0.6);
        }
    }
    .price-section {
        .container-fluid {
            padding-left: 25px;
            padding-right: 25px;
        }
        .price-box {
            .price-img {
                .bg-size {
                    &:before {
                        padding-top: 50%;
                    }
                }
            }
        }
    }
    footer {
        .footer {
            .footer-title {
                &.mobile-title {
                    display: block;
                }
                h5 {
                    margin-bottom: 20px;
                }
                .according-menu {
                    display: block;
                }
            }
            .footer-content {
                margin-bottom: 20px;
                .contact-detail {
                    .footer-logo {
                        margin-bottom: 15px;
                    }
                    .contact-list {
                        li {
                            color: rgba(white, 0.7);
                        }
                    }
                }
                .footer-place {
                    .place {
                        text-align: center;
                    }
                }
            }
            .order-row {
                >div {
                    &:nth-child(3),
                    &:last-child {
                        margin-top: 0;
                    }
                    &:last-child {
                        .footer-title {
                            h5 {
                                margin-bottom: 0;
                            }
                        }
                        .footer-content {
                            margin-bottom: 0;
                            margin-top: 20px;
                        }
                    }
                }
            }
        }
        .sub-footer {
            .footer-social {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 5px;
            }
            .copy-right {
                p {
                    text-align: center;
                }
            }
        }
    }
    .tap-top {
        font-size: 20px;
        padding: 5px 5px 5px 5px;
        width: 30px;
        height: 30px;
        bottom: 40px;
    }
    .process-steps {
        .step-bg {
            .row {
                >div {
                    margin-top: 20px;
                    &:nth-child(1),
                    &:nth-child(3) {
                        margin-bottom: 0;
                    }
                    &:nth-child(2),
                    &:nth-child(4) {
                        margin-top: 20px;
                    }
                    &:first-child {
                        margin-top: 0;
                    }
                }
                &.popular-section {
                    >div {
                        &:nth-child(3),
                        &:nth-child(4) {
                            margin-top: 30px;
                        }
                    }
                }
            }
            &:before {
                transform: rotate(90deg);
            }
        }
    }
    .filter-panel {
        &.filter-title-bar {
            text-align: center;
            h4 {
                padding-bottom: 10px;
            }
        }
    }
    .subscribe-footer {
        .service-left {
            li {
                display: block;
                margin-top: 20px;
                .service-box {
                    display: flex;
                    padding: 0;
                    border: none;
                }
                &:first-child {
                    margin-top: 0;
                }
            }
        }
        .service-right {
            width: 90%;
        }
    }
    .video-slider {
        height: 270px;
    }
    .loader-wrapper {
        &.img-gif {
            img {
                width: 70%;
            }
        }
    }
    .testimonial_section {
        .testimonial {
            .bottom-part {
                p {
                    max-width: 100%;
                }
            }
        }
    }
    // inner pages
    .facts-section {
        .facts-box {
            padding: 30px;
            .img {
                margin-bottom: 7px;
            }
        }
    }
    .special-section {
        &.grid-box {
            .special-box {
                .special-content {
                    p {
                        line-height: 1.2;
                    }
                    .bottom-section {
                        .price {
                            display: block;
                            .facility-detail {
                                margin-left: 0;
                                margin-top: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
    .search-panel {
        .search-section {
            .search-box {
                .left-part {
                    display: block;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    .search-body {
                        width: 49%;
                        padding: 14px 10px;
                        &:nth-child(2),
                        &:nth-child(4) {
                            &:after {
                                display: none;
                            }
                        }
                        &.btn-search {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    .list-view {
        .list-box {
            display: block;
            .list-img {
                width: 100%;
                img {
                    width: 100%;
                }
            }
            .list-content {
                width: 100%;
                margin-left: 0;
                margin-top: 10px;
                .offer-box {
                    position: relative;
                    max-width: 100%;
                    border: none;
                    padding: 0;
                }
                p {
                    margin-bottom: 10px;
                }
                .facility-icon {
                    margin-top: 18px;
                }
                .offer-box {
                    margin-top: 7px;
                }
                h6 {
                    max-width: 100%;
                }
            }
        }
    }
    .single-section {
        .description-section {
            .menu-top {
                margin-top: 10px;
                li {
                    a {
                        padding: 0 8px;
                    }
                    &.active {
                        a {
                            border-bottom: none;
                        }
                    }
                }
            }
            .description-details {
                .menu-part {
                    margin-top: 10px;
                    &.map {
                        iframe {
                            height: 250px;
                        }
                    }
                }
            }
            &.tab-section {
                .menu-top {
                    li {
                        a {
                            padding: 11px 8px;
                        }
                    }
                }
                .description-details {
                    .menu-part {
                        .margin-up {
                            margin-top: 20px;
                        }
                    }
                }
            }
        }
    }
    .hotel-single-section {
        .hotel-title-section {
            .hotel-name {
                padding: 40px 0 20px 0;
                .left-part {
                    .top {
                        margin-bottom: 10px;
                    }
                }
            }
        }
        &.parallax-img {
            background-attachment: unset;
        }
    }
    .hotel_title_section {
        .hotel-name {
            padding: 0 0 30px 0;
            .left-part {
                .top {
                    margin-bottom: 10px;
                }
            }
        }
    }
    .detail-menu {
        .nav-tabs {
            .nav-link {
                padding: 7px;
            }
        }
    }
    .booking-bottom-section {
        .row {
            >div {
                &:nth-child(2) {
                    margin-top: 20px;
                }
            }
        }
    }
    .full-width-detail {
        .about-section {
            margin-top: 30px;
        }
        .place-detail {
            li {
                padding: 0 6px;
                +li {
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }
    .order-food-section {
        .order-food {
            align-items: center;
            .book-table {
                &.single-table {
                    .table-form {
                        margin-bottom: -90px;
                    }
                }
            }
        }
    }
    .view-map {
        .arrow {
            font-size: 12px;
            left: 40px;
            top: -39px;
            &:after {
                width: 30px;
                height: 30px;
                left: -37px;
                bottom: -15px;
            }
        }
    }
    .order-menu-section {
        .order-section {
            .order-items {
                .items {
                    p {
                        max-width: 74%;
                    }
                }
            }
        }
    }
    .flight-detail-sec {
        .detail-bar {
            .detail-wrap {
                padding: 10px 15px 15px;
                .airport-part {
                    width: 70%;
                    margin: 0 auto;
                }
                .price {
                    display: block;
                    margin-top: 30px;
                }
                .book-flight {
                    margin-top: 5px;
                }
            }
        }
        .title-bar {
            display: none;
        }
        &.full_width-detail {
            .detail-bar {
                .detail-wrap {
                    padding: 10px 15px 15px;
                }
            }
        }
        &.cab-detail-sec {
            .detail-bar {
                .detail-wrap {
                    .car-details {
                        margin-top: 15px;
                    }
                }
            }
        }
        &.book_panel {
            .grand-total {
                padding: 12px 0;
                .total {
                    margin-right: auto;
                }
            }
        }
    }
    .review-section {
        .review_box {
            .flight_detail {
                .form_flight {
                    form {
                        .form-group {
                            margin-bottom: 20px;
                        }
                        .form-row {
                            .form-group {
                                &:last-child {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
                .logo-sec {
                    margin-bottom: 10px;
                }
                .duration {
                    margin-top: 10px;
                }
                .addons-according {
                    .seat-select {
                        .seat-details {
                            position: relative;
                            top: 0;
                            margin-bottom: 15px;
                        }
                    }
                }
            }
        }
    }
    .success-section {
        .success-detail {
            p {
                line-height: 24px;
            }
        }
    }
    .filter-panel {
        display: block;
        .filter-bottom-title {
            margin-top: 20px;
            border-top: 1px solid $inner-bg;
            width: 100%;
            padding-top: 20px;
            h5 {
                margin-left: auto;
            }
        }
        .right-panel {
            &.search_filter {
                margin-top: 20px;
                border-top: 1px solid $inner-bg;
                width: 100%;
                padding-top: 20px;
                .filter-bottom-title {
                    padding-left: 0;
                    padding-top: 0;
                    margin-top: 0;
                    border-top: none;
                    width: 50%;
                }
                .view-map {
                    width: 50%;
                }
            }
        }
        &.right-filter,
        &.filter-map {
            display: flex;
        }
    }
    .portfolio-creative {
        .content-sec {
            p {
                max-width: 100%;
            }
        }
    }
    .blog-inner {
        &.blog_section {
            .blog-list {
                .blog-wrap {
                    display: block;
                    .blog-image {
                        width: 100%;
                    }
                    .blog-details {
                        width: 100%;
                        p {
                            -webkit-line-clamp: 3;
                        }
                    }
                }
            }
        }
    }
    .blog-single-detail {
        .title-part {
            .post-detail {
                li {
                    display: block;
                    +li {
                        margin-left: 0;
                        padding-left: 0;
                        border-left: none;
                    }
                }
            }
        }
    }
    .contact-map {
        margin-top: 30px;
        height: auto;
    }
    .contact_right {
        &.contact_section {
            order: -1;
        }
    }
    .dashboard-section {
        .dashboard-box {
            .dashboard-detail {
                .booking-box {
                    display: block;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    position: relative;
                    .detail-middle {
                        width: 100%;
                        margin-top: 15px;
                        text-align: left;
                    }
                    .detail-last {
                        margin-top: 0;
                        position: absolute;
                        right: 10px;
                        top: 10px;
                    }
                }
                .card-payment {
                    .payment-card {
                        padding: 15px 10px;
                        .edit-card {
                            display: none;
                        }
                        &.add-card {
                            .card-details {
                                min-height: 130px;
                            }
                        }
                    }
                    .edit-card-mobile {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-top: 10px;
                        a {
                            padding: 0 10px;
                            color: black;
                        }
                    }
                }
            }
        }
        .dashboard-sidebar {
            .profile-top {
                .profile-image {
                    .profile-edit {
                        right: 37%;
                    }
                }
            }
        }
        .dashboard-main {
            .dashboard-info {
                .activity-box {
                    margin-top: 25px;
                }
            }
        }
    }
    .account-sign-in {
        .login-with {
            .login-social {
                .boxes {
                    padding: 20px 0;
                    h6 {
                        margin-left: 10px;
                    }
                }
            }
        }
    }
    .checkout-process {
        .checkout-box {
            .address-sec {
                .select-box {
                    margin-top: 25px;
                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }
    }
    // skeleton loader
    .skeleton_loader {
        .blog-inner {
            &.blog_section {
                .blog-list {
                    .blog-wrap {
                        .blog-details {
                            &.text-md-right {
                                h6,
                                h5,
                                span,
                                p {
                                    margin-right: auto;
                                    margin-left: unset;
                                }
                            }
                        }
                    }
                }
            }
        }
        .search-panel {
            .search-section {
                .search-box {
                    .left-part {
                        .search-body {
                            width: 49%;
                            &.btn-search {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
        .hotel_title_section {
            .hotel-name {
                .left-part {
                    .top {
                        h2 {
                            width: 220px;
                        }
                    }
                }
                .right-part {
                    .price {
                        width: 110px;
                        margin-left: auto;
                    }
                }
            }
        }
        .single-section {
            .description-section {
                &.tab-section {
                    .menu-top {
                        li {
                            margin: 10px 6px 0;
                            a {
                                padding: 8px 16px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 991px) and (min-width: 768px) {
    .container {
        &.custom-container {
            max-width: 820px;
        }
    }
}

@media (width: 640px) and (height: 360px) {
    .cab-section {
        height: 200vh;
        .cab-content {
            height: 200vh;
        }
        &.flight-section {
            height: 100%;
            padding-bottom: 25px;
            .cab-content {
                height: 100%;
                padding-bottom: 25px;
            }
        }
    }
    .home_section {
        .home {
            height: 130vh;
            .home-content {
                height: 130vh;
            }
            &.home-long {
                height: 130vh;
                .home-content {
                    height: 130vh;
                }
            }
            &.home-mobile {
                height: 140vh;
                .home-content {
                    &.smaller-content {
                        height: 140vh;
                    }
                }
            }
        }
    }
    .search-section {
        margin-top: -15px;
    }
    .full-page {
        height: 220vh;
        .single-section {
            height: 220vh;
        }
    }
}

@media (max-width: 576px) {
    section,
    .section-t-space {
        padding-top: 40px;
    }
    .section-b-space {
        padding-bottom: 40px;
    }
    .medium-section {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .radius-cls {
        border-radius: 2px;
    }
    .btn {
        font-size: 14px;
        line-height: 18px;
        &.btn-sm {
            padding: 6px 14px;
        }
        &.btn-solid {
            padding: 7px 12px;
        }
    }
    .btn-solid,
    .btn-curve {
        padding: 7px 12px;
    }
    .btn-rounded {
        padding: 9px 22px;
    }
    .title-1 {
        .title-label {
            padding: 3px 14px;
        }
        &.title-5 {
            p {
                margin-bottom: 20px;
            }
        }
    }
    .title-2 {
        p {
            margin-bottom: 20px;
        }
    }
    .title-3 {
        h2 {
            margin-bottom: 20px;
        }
        .title-label {
            padding: 3px 14px;
        }
    }
    header {
        .menu {
            .header-right {
                li {
                    +li {
                        margin-left: 10px;
                    }
                }
                .setting {
                    .setting-open {
                        bottom: -80px;
                    }
                }
            }
        }
        .mix-pills {
            &.nav-pills {
                .nav-link {
                    padding: 0 8px;
                    &:after {
                        bottom: -10px;
                    }
                }
            }
        }
    }
    .breadcrumb-section {
        height: 270px;
        .breadcrumb-content {
            height: 270px;
            padding-top: 60px;
            >div {
                padding: 20px 25px;
                margin: 0 10px;
            }
            h2 {
                margin-bottom: 5px;
                margin-top: -4px;
            }
        }
        .title-breadcrumb {
            font-size: 290px;
        }
        &.breadcrumb-cum-header {
            height: 300px;
            .breadcrumb-content {
                height: 300px;
            }
        }
    }
    .home_section {
        .home {
            .home-content {
                padding-top: 60px;
                h5 {
                    margin-bottom: 2px;
                    letter-spacing: 0.01em;
                }
                .btn {
                    padding: 6px 10px;
                }
                >div {
                    padding: 20px 70px;
                }
                .flight-search {
                    .flight-search-detail {
                        padding: 10px 0;
                        form {
                            .col {
                                &:nth-child(even) {
                                    padding-left: 5px;
                                }
                                &:nth-child(odd) {
                                    padding-right: 5px;
                                }
                                &.search-col {
                                    margin-right: 10px;
                                    margin-left: 50%;
                                }
                            }
                        }
                    }
                }
                &.food_content {
                    padding-top: 30px;
                    >div {
                        padding: 20px 30px;
                    }
                    h1 {
                        margin-bottom: 10px;
                    }
                }
                &.mix-layout {
                    .flight-search {
                        .flight-search-detail {
                            form {
                                .col {
                                    &.search-col {
                                        margin-right: 0;
                                    }
                                }
                            }
                        }
                    }
                    &.smaller-content {
                        .search-panel {
                            .search-section {
                                .search-box {
                                    .left-part {
                                        .search-body {
                                            padding: 6px 6px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &.home-padding {
                .home-content {
                    padding: 30px 0 30px 0;
                }
            }
            &.home-long {
                height: 70vh;
                .home-content {
                    height: 70vh;
                    &.mix-layout {
                        .flight-search {
                            .flight-search-detail {
                                form {
                                    >div {
                                        padding-left: 5px !important;
                                        padding-right: 5px !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &.home-mobile {
                .home-content {
                    &.smaller-content {
                        padding-top: 30px;
                        h1 {
                            font-size: calc(14px + (60 - 14) * ((100vw - 320px) / (1920 - 320)));
                        }
                    }
                }
            }
        }
        .slick-prev,
        .slick-next {
            padding: 15px;
            &:after {
                font-size: 14px;
            }
        }
        .offer-text {
            display: none;
        }
    }
    .home_effect {
        .effect_image {
            img {
                bottom: -60px;
            }
        }
    }
    .tour_layout {
        .flight-search {
            .flight-search-detail {
                .form-group {
                    .gj-datepicker,
                    .gj-timepicker {
                        button {
                            width: 40px;
                            i {
                                top: 9px;
                                left: 9px;
                            }
                        }
                    }
                    img {
                        padding: 9px;
                    }
                }
            }
        }
    }
    .location-option {
        margin-bottom: 8px;
        [type="radio"]:checked+label:before,
        [type="radio"]:not(:checked)+label:before {
            top: 0;
        }
    }
    .classic-slider {
        .slick-dots {
            top: 80%;
            display: flex;
            left: 50%;
            transform: translateX(-50%);
            align-items: center;
            justify-content: center;
            li {
                margin: 0 5px;
                .dot {
                    opacity: 0.7;
                }
            }
        }
    }
    .cab-section {
        .cab-content {
            padding-top: 56px;
            h2 {
                margin-bottom: 4px;
            }
            form {
                .form-control {
                    padding: 6px 14px !important;
                }
                .form-group {
                    margin-bottom: 10px;
                    img {
                        padding: 9px !important;
                    }
                }
                .gj-datepicker,
                .gj-timepicker {
                    button {
                        width: 37px;
                    }
                }
                .row {
                    >div {
                        margin-top: 10px;
                    }
                }
            }
            .car-select {
                .btn {
                    width: 100px;
                    padding: 6px 15px;
                }
            }
            .call-section {
                .call {
                    padding: 6px 6px;
                    margin-bottom: 6px;
                    i {
                        margin-right: 18px;
                    }
                    &:after {
                        width: 30px;
                        height: 30px;
                    }
                }
            }
            .radio-form {
                margin-bottom: 15px;
                [type="radio"]:checked+label,
                [type="radio"]:not(:checked)+label {
                    padding-left: 30px;
                }
                label {
                    margin-right: 10px;
                }
            }
        }
        &.flight-section {
            .form-group {
                &.row {
                    display: block;
                    width: 100%;
                    margin: 0 !important;
                    .col {
                        padding: 0 !important;
                        margin-bottom: 5px;
                    }
                }
            }
            .cab-content {
                padding-top: 70px;
                >div {
                    width: 100%;
                }
                .btn-rounded {
                    padding: 7px 15px;
                }
                .top-cls {
                    display: none;
                }
            }
        }
    }
    .arrow-classic {
        .slick-next,
        .slick-prev {
            display: none !important;
        }
    }
    .app-section {
        .app-content {
            .app-buttons {
                a {
                    padding: 6px 8px;
                }
            }
            .label {
                span {
                    padding: 3px 12px;
                    &:after {
                        right: -47px;
                        width: 50px;
                        height: 22px;
                    }
                }
            }
        }
        .app-image {
            .image {
                &:last-child {
                    margin-left: 10px;
                }
            }
        }
        .app-box {
            padding: 30px;
            background-color: rgba(black, 0.1);
            h2,
            h5 {
                margin-bottom: 10px;
            }
            .app-buttons {
                a {
                    padding: 8px 14px;
                }
            }
        }
        .order-cls {
            >div {
                &:first-child {
                    margin-top: 15px;
                }
            }
        }
        &.app-right-sec {
            .app-content {
                padding: 30px;
            }
        }
    }
    .circle {
        height: 80px;
        width: 80px;
        left: 60px;
        &.b-round {
            height: 130px;
            width: 130px;
        }
    }
    .service-section {
        .service-box {
            padding: 30px;
            box-shadow: 10px 17.321px 30px 0px rgba(206, 206, 206, 0.19);
        }
    }
    .search-section {
        margin-top: -25px;
    }
    .testimonial-section {
        .testimonial {
            display: block;
            text-align: center;
            padding-bottom: 40px;
            .right-part {
                margin-left: 0;
                max-width: 100%;
                p {
                    margin-bottom: 10px;
                }
                .detail {
                    justify-content: center;
                }
            }
            .quote-icon {
                display: none;
            }
        }
        .animation-section {
            opacity: 0.1;
        }
    }
    .subscribe-section {
        .input-group {
            .btn {
                padding: 10px 16px;
            }
            .form-control {
                padding: 10px 15px;
            }
        }
    }
    .testimonial_section {
        .testimonial {
            .top-part {
                .img-part {
                    margin-top: 40px;
                }
            }
        }
    }
    .subscribe_section {
        .input-section {
            input {
                border-radius: 10px;
                padding: 20px 120px 20px 20px;
            }
            .btn {
                right: 20px;
                padding: 6px 12px;
            }
        }
    }
    .about-section {
        .image-section {
            .img-box {
                .no-class {
                    bottom: -15px;
                    top: unset;
                    left: 50%;
                    transform: translateX(-50%);
                }
                +.img-box {
                    margin-left: 15px;
                }
            }
        }
        &.three-image {
            .about-text {
                img {
                    width: 122px;
                }
            }
        }
        &.about_page {
            .image-section {
                .img-box {
                    .title-box {
                        padding: 8px;
                        left: 0;
                        transform: none;
                        width: 100%;
                        min-width: 100%;
                        h3 {
                            margin-bottom: 0;
                        }
                    }
                    &:nth-child(odd) {
                        margin-bottom: 25px;
                        .title-box {
                            bottom: -25px;
                        }
                    }
                    &:nth-child(even) {
                        margin-top: 25px;
                        .title-box {
                            top: -25px;
                        }
                    }
                }
            }
            .about-text {
                margin-top: 20px;
                .title-3 {
                    margin-bottom: 15px;
                }
                .buttons-about {
                    margin-top: 15px;
                }
            }
        }
    }
    .ticket-section {
        .ticket-box {
            .content {
                padding: 25px;
                .barcode-design {
                    display: none;
                }
                .detail {
                    max-width: 100%;
                }
            }
        }
        .slick-next,
        .slick-prev {
            &:before {
                padding: 2px;
                font-size: 21px;
            }
        }
        .slick-prev {
            left: -8px;
        }
        &.white-section {
            .ticket-box {
                margin-bottom: 40px;
            }
            &.section-b-space {
                padding-bottom: 0;
            }
        }
    }
    .timer {
        li {
            margin-right: 5px;
        }
    }
    .video-icon {
        width: 46px;
        height: 30px;
        border-radius: 6px;
        span {
            border-top: 6px solid transparent;
            border-left: 12px solid white;
            border-bottom: 6px solid transparent;
        }
    }
    .menu-section {
        .slick-slide {
            margin: 0 15px;
        }
    }
    .topTour {
        .topTour_box {
            box-shadow: none;
        }
    }
    .video-section {
        padding: 60px;
        .basic-section {
            h4 {
                letter-spacing: 0.2em;
            }
            h2,
            h4 {
                margin-bottom: 15px;
            }
            .video-icon {
                .animation-circle-inverse {
                    i {
                        border-radius: 6px;
                    }
                }
            }
        }
    }
    .video_section {
        padding: 60px 0;
        .video-content {
            h5 {
                margin-bottom: 12px;
            }
            h2 {
                margin-bottom: 10px;
            }
            p {
                margin-bottom: 12px;
            }
            .bottom-section {
                margin-top: 20px;
                .info-btn {
                    margin-left: 10px;
                }
            }
        }
        .video-image {
            img {
                border-radius: 15px;
            }
            .side-effect {
                &:before {
                    width: calc(100% + 50px);
                    left: -25px;
                    border-radius: 15px;
                }
                &:after {
                    border-radius: 15px;
                }
            }
        }
        .order-cls {
            margin-bottom: 30px;
        }
        .offer-text {
            display: none;
        }
    }
    .gallery-section {
        .zoom-gallery {
            >div {
                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4) {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
        }
    }
    .blog-section {
        .blog-box {
            .img-part {
                img {
                    min-width: 180px;
                }
            }
        }
    }
    .blog_section {
        &.section-b-space {
            padding-bottom: 10px;
        }
        &.destination-section {
            .slick-slide {
                >div {
                    margin: 0 15px;
                }
            }
            .slick-list {
                margin-left: -15px;
                margin-right: -15px;
            }
        }
    }
    .special-section {
        .special-box {
            .special-content {
                padding: 20px;
            }
            .special-img {
                .label {
                    left: 15px;
                    top: 15px;
                    i {
                        padding: 9px 10px;
                    }
                }
                .content-inner {
                    bottom: 15px;
                    background-color: rgba(white, 0.78);
                }
            }
        }
        &.section-b-space {
            padding-bottom: 10px;
        }
        &.animated-section {
            .animation-section {
                opacity: 0.1;
            }
        }
    }
    .full-banner {
        padding: 50px 0;
        &.banner-section {
            .banner-content {
                margin-top: 15px;
            }
        }
    }
    .category-section {
        .slick-list {
            margin-left: -15px;
            margin-right: -15px;
        }
        .slick-slide {
            >div {
                margin: 0 15px;
            }
        }
    }
    .category-sec {
        &.section-b-space {
            padding-bottom: 0;
        }
    }
    .category-wrapper {
        .category-wrap {
            margin: 20px 15px 30px;
            .category-content {
                .top {
                    display: block;
                    margin-bottom: 7px;
                    h3 {
                        margin-bottom: 7px;
                    }
                    h6 {
                        margin-left: 0;
                    }
                }
                .bottom {
                    display: block;
                    h3 {
                        margin-bottom: 7px;
                    }
                    .coupon-code {
                        margin-left: 0;
                    }
                }
                p {
                    -webkit-line-clamp: 2;
                }
            }
        }
        &.section-b-space {
            padding-bottom: 10px;
        }
    }
    .category-bg {
        .category-block {
            padding: 70px 20px 30px;
            max-width: 70%;
            margin: 35px auto 10px;
        }
        &.section-b-space {
            padding-bottom: 30px;
        }
    }
    .collection-banner {
        .contain-banner {
            h4 {
                margin-bottom: 5px;
            }
        }
    }
    .detail-section {
        .detail-box {
            .upper-part {
                h6 {
                    margin-bottom: 10px;
                }
                h2 {
                    padding-bottom: 15px;
                    margin-bottom: 15px;
                }
            }
        }
        &.no-bg-detail {
            .detail-box {
                .upper-part {
                    min-height: auto;
                }
            }
        }
    }
    .about_section {
        .about_img {
            margin: 10px 15px;
            .side-effect {
                &:after {
                    top: -5px;
                    left: 72px;
                }
                &:before {
                    top: -10px;
                    left: 110px;
                }
                span {
                    &:after {
                        bottom: -5px;
                        right: 72px;
                    }
                    &:before {
                        bottom: -10px;
                        right: 110px;
                    }
                }
            }
        }
    }
    .loader-wrapper {
        .loader {
            .chev1,
            .chev5 {
                width: 52px;
                height: 52px;
            }
            .chev2,
            .chev4 {
                width: 70px;
                height: 70px;
            }
            .chev3 {
                width: 90px;
                height: 90px;
            }
        }
    }
    .filter-section {
        .content {
            .grid-item {
                .filter-detail {
                    h6 {
                        letter-spacing: 0.1em;
                    }
                }
                .img-part {
                    .up-btn {
                        width: 40px;
                        height: 40px;
                        i {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
    .food-banner {
        padding: 70px 0;
        background-position: right;
    }
    .discount-banner {
        padding: 60px 0;
        .banner-content {
            h4 {
                margin-bottom: 14px;
            }
            h2 {
                margin-bottom: 15px;
            }
            .coupon {
                margin-bottom: 18px;
                padding: 3px;
                h5 {
                    padding: 3px;
                }
            }
            p {
                margin-bottom: 15px;
                letter-spacing: 0.2em;
            }
        }
    }
    .book-table {
        &.single-table {
            .table-form {
                margin-top: -30px;
            }
        }
        .table-form {
            form {
                .form-group {
                    input {
                        padding: 15px 15px 13px;
                    }
                    img {
                        right: 15px;
                        top: 15px;
                    }
                }
                .form-control {
                    padding: 15px 15px 13px;
                }
                .input-group-append {
                    .btn {
                        i {
                            top: 4px;
                            left: 14px;
                        }
                    }
                }
            }
        }
    }
    .car-type-section {
        .type-box {
            padding: 15px 5px;
        }
    }
    .location-option {
        [type="radio"]:checked+label:before,
        [type="radio"]:not(:checked)+label:before {
            width: 12px;
            height: 12px;
            border: 3px solid #fff;
        }
        [type="radio"]:checked+label:before {
            border: 3px solid $theme-color1;
        }
        [type="radio"]:checked+label,
        [type="radio"]:not(:checked)+label {
            padding-left: 20px;
        }
    }
    .full-page {
        .full-slider {
            .slick-next,
            .slick-prev {
                display: none !important;
            }
            .slick-dots {
                li {
                    margin: 0 6px;
                }
            }
        }
    }
    .cab-full {
        .left-bar {
            &.fixed-cls {
                .flight-search {
                    .flight-search-detail {
                        padding: 15px 15px 0;
                        form {
                            >div {
                                &:first-child {
                                    margin-top: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .location-option {
            [type="radio"]:checked+label:before,
            [type="radio"]:not(:checked)+label:before {
                top: 3px;
            }
        }
    }
    .top-banner {
        .top-banner-content {
            font-size: 26px;
        }
        .flip {
            >div {
                >div {
                    margin-bottom: 50px;
                }
            }
        }
    }
    .parallax-effect {
        .food-img {
            &.food1 {}
            &.food2 {
                img {
                    width: 40px;
                }
            }
            &.food3 {
                img {
                    width: 50px;
                }
            }
            &.food4 {
                img {
                    width: 30px;
                }
            }
        }
        img {
            width: 50px;
        }
    }
    footer {
        .footer {
            .footer-title {
                h5 {
                    margin-bottom: 15px;
                }
            }
            .footer-content {
                margin-bottom: 15px;
            }
            .order-row {
                >div {
                    &:last-child {
                        .footer-content {
                            margin-top: 15px;
                        }
                    }
                }
            }
        }
        .sub-footer {
            padding: 15px 0;
        }
    }
    .video-slider {
        height: 220px;
    }
    .process-steps {
        .step-bg {
            .row {
                &.popular-section {
                    >div {
                        &:nth-child(2),
                        &:nth-child(4) {
                            margin-top: 30px;
                        }
                    }
                }
            }
        }
        .bird-container {
            top: 10%;
        }
    }
    .mix-pills {
        margin-bottom: 10px !important;
        &.nav-pills {
            .nav-link {
                padding: 8px;
            }
        }
    }
    // inner pages
    .team-section {
        .team-box {
            .team-content {
                min-width: 160px;
                padding: 15px;
                h3 {
                    margin-bottom: 3px;
                    margin-top: -2px;
                }
            }
            .team-social {
                bottom: 40px;
                .social-box {
                    opacity: 1;
                    margin: 0 2px;
                }
            }
            &:hover {
                .team-social {
                    .social-box {
                        transform: scale(0.8);
                    }
                }
            }
        }
        .slick-dots {
            padding-top: 15px;
            li {
                margin: 0 2px;
                button {
                    &:before {
                        font-size: 10px;
                    }
                }
                &.slick-active {
                    button {
                        &:after {
                            font-size: 20px;
                            left: 0px;
                        }
                    }
                }
            }
        }
    }
    .facts-section {
        .row {
            >div {
                &:nth-last-child(-n + 2) {
                    margin-top: 20px;
                }
            }
        }
        .facts-box {
            padding: 10px;
            .img {
                img {
                    height: 40px;
                    width: auto;
                }
            }
        }
        .animated-section {
            .animation-section {
                opacity: 0.1;
            }
        }
    }
    .filter-panel {
        .respon-filter-btn {
            display: block;
            cursor: pointer;
            h6 {
                font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
                color: black;
                font-weight: 700;
                margin-bottom: 0;
            }
        }
        .respon-filter-content {
            display: none;
            &.show {
                display: block;
                position: absolute;
                background-color: white;
                width: 150px;
                z-index: 2;
                left: 15px;
                top: 45px;
                box-shadow: -4px -1px 20px 0px rgba(0, 0, 0, 0.13);
                ul {
                    li {
                        display: block;
                        background-color: white;
                        &.active {
                            background-color: white;
                            color: $theme-color1;
                        }
                    }
                }
            }
        }
        &.right-filter {
            .respon-filter-content {
                left: unset;
                right: 15px;
            }
            &.open-cls {
                .respon-filter-content {
                    right: unset;
                    left: 15px;
                }
            }
        }
    }
    .map-section {
        margin-top: 0;
        iframe,
        .map {
            height: 280px;
        }
    }
    .pagination-section {
        margin-top: 30px;
    }
    .single-section {
        .description-section {
            .description-details {
                .menu-part {
                    margin-bottom: 20px;
                    padding: 15px;
                    &.about {
                        .about-sec {
                            p {
                                line-height: 1.3;
                            }
                            ul {
                                li {
                                    line-height: 1.3;
                                }
                            }
                        }
                    }
                    &.review {
                        .review-box {
                            p {
                                line-height: 1.3;
                            }
                        }
                    }
                    .zoom-gallery {
                        >div {
                            &:nth-child(-n + 5) {
                                margin-bottom: 20px;
                            }
                        }
                    }
                }
            }
        }
        .single-sidebar {
            .social-box {
                margin-top: 10px;
            }
            p {
                line-height: 1.3;
            }
            .book-btn-section {
                margin-top: 0;
                padding-top: 5px;
            }
        }
    }
    .d-small-none {
        display: none;
    }
    .hotel-single-section {
        height: 350px;
        .hotel-title-section {
            background: linear-gradient(to top, rgba(0, 0, 0, 0.7) 50%, rgba(231, 56, 39, 0) 100%);
            .hotel-name {
                display: block;
                padding: 40px 0 10px 0;
                .left-part {
                    margin-bottom: 8px;
                    .facility-detail {
                        display: none;
                    }
                    .top {
                        margin-bottom: 10px;
                    }
                }
                .right-part {
                    text-align: left;
                    margin-top: 0;
                    .btn {
                        display: none;
                    }
                    .price {
                        margin-bottom: -5px;
                    }
                }
            }
        }
    }
    .hotel_title_section {
        .hotel-name {
            display: block;
            padding: 0 0 20px 0;
            .left-part {
                .facility-detail {
                    display: none;
                }
                .top {
                    margin-bottom: 5px;
                }
                p {
                    margin-bottom: 5px;
                }
            }
            .right-part {
                text-align: left;
                margin-top: 0;
                .btn {
                    display: none;
                }
                .price {
                    margin-bottom: -5px;
                }
            }
        }
    }
    .variable-width {
        .slick-slide {
            img {
                height: 320px;
            }
        }
    }
    .summery-box {
        padding: 15px;
        .hotel-section {
            .hotel-detail {
                margin-left: 8px;
            }
        }
    }
    .order-food-section {
        .order-food {
            align-items: center;
            .book-table {
                &.single-table {
                    .table-form {
                        margin-bottom: -40px;
                    }
                }
            }
        }
    }
    .guest-detail {
        padding: 15px;
        form {
            .form-group {
                margin-bottom: 10px;
            }
            .form-control {
                padding: 5px;
            }
            #mobile-no {
                padding: 5px 5px 5px 48px;
            }
            .submit-btn {
                .btn {
                    width: 100%;
                    margin-top: 10px;
                }
            }
        }
    }
    .book-panel {
        display: flex;
    }
    .full-width-detail {
        .about-section {
            margin-top: 20px;
        }
    }
    .left-sidebar {
        &.sidebar-popup {
            top: 70px;
        }
    }
    .top-title-bar {
        .hotel_title_section {
            display: block;
            text-align: center;
            .right-part {
                text-align: center;
            }
            ul {
                justify-content: center;
                margin-top: 10px;
            }
        }
    }
    .infoBox {
        .marker-detail {
            img {
                width: 130px;
            }
        }
    }
    .flight-search {
        .flight-search-detail {
            form {
                width: 80%;
                input {
                    font-size: 12px;
                }
                ::placeholder {
                    font-size: 12px;
                }
            }
            .form-group {
                img {
                    padding: 7px;
                }
                .gj-datepicker {
                    button {
                        i {
                            top: 6px;
                        }
                    }
                }
                label {
                    font-size: 12px;
                }
            }
            .search-btn {
                .btn {
                    padding: 5px 20px;
                }
            }
        }
    }
    .top-bar-flight {
        .date-fare-slider {
            padding: 0 30px;
            width: 75%;
            .slick-slider {
                .slick-prev,
                .slick-next {
                    &:before {
                        font-size: 14px;
                    }
                }
                .slick-prev {
                    left: -26px;
                }
                .slick-next {
                    right: -26px;
                }
            }
        }
        .fare-calender {
            width: 25%;
        }
    }
    .review-section {
        .review_box {
            .flight_detail {
                padding: 10px;
                .form_flight {
                    form {
                        select.form-control:not([size]):not([multiple]) {
                            height: 32px;
                        }
                        .form-control {
                            padding: 3px 6px;
                        }
                    }
                }
                .promo-section {
                    .form-control {
                        padding: 3px 6px;
                    }
                }
                .addons-according {
                    .seat-select {
                        .plane {
                            .cockpit {
                                height: 110px;
                                &:before {
                                    height: 225px;
                                }
                            }
                            .seat {
                                padding: 2px 2px;
                            }
                        }
                    }
                }
                &.payment-gateway {
                    form {
                        .form-group {
                            img {
                                height: 14px;
                                bottom: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
    .success-section {
        .animation {
            height: 13rem;
            .animation__plane {
                width: 12rem;
            }
            .animation__plane--shadow {
                width: 6rem;
                height: 0.5rem;
            }
        }
    }
    .cab-single-detail {
        .details {
            >div {
                &:nth-child(2) {
                    margin-top: 15px;
                }
            }
        }
    }
    .portfolio-section {
        &.creative-section {
            .filter-button-group {
                margin-bottom: 30px;
            }
        }
    }
    .contact_section {
        .row {
            >div {
                &:nth-child(-n + 3) {
                    margin-bottom: 25px;
                }
            }
        }
    }
    .get-in-touch {
        form {
            .form-control {
                padding: 6px 12px;
            }
        }
    }
    .coming-soon {
        .coming-soon-detail {
            form {
                width: 80%;
            }
        }
    }
    .faq-content {
        padding: 15px 10px;
    }
    .faq-tab {
        .nav-tabs {
            .nav-item {
                .nav-link {
                    padding: 3px 10px;
                }
            }
        }
    }
    .account-sign-in {
        form {
            .button-bottom {
                .divider {
                    margin: 10px 0;
                }
            }
        }
    }
    .dashboard-section {
        .dashboard-main {
            .counter-section {
                .row {
                    >div {
                        &:nth-child(-n + 3) {
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }
        .dashboard-box {
            .dashboard-detail {
                .booking-box {
                    text-align: left;
                    padding: 15px;
                }
                .card-payment {
                    >div {
                        &:nth-child(3),
                        &:nth-child(2) {
                            margin-top: 20px;
                        }
                    }
                    .payment-card {
                        &.add-card {
                            margin-top: 20px;
                        }
                    }
                }
            }
        }
    }
    .checkout-cart {
        .single-sidebar {
            &.order-cart-right {
                padding: 20px 20px;
            }
        }
    }
    // skeleton loader
    .skeleton_loader {
        .filter-panel {
            .respon-filter-btn {
                h6 {
                    width: 48px;
                    height: 16px;
                    background: linear-gradient(90deg, rgba(212, 212, 212, 0.3) 8%, #e4e4e4 18%, rgba(212, 212, 212, 0.3) 33%);
                    background-size: 800px 104px;
                    animation: skeleton-loader 2s infinite linear;
                }
            }
        }
        .top-title-bar {
            .hotel_title_section {
                h2,
                h6,
                ul {
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
        .single-section {
            .image_section {
                .left {
                    .ldr-img {
                        height: 180px;
                    }
                }
            }
        }
        .hotel_title_section {
            .hotel-name {
                .left-part {
                    .top {
                        .rating {
                            width: 20%;
                        }
                    }
                }
                .right-part {
                    .price {
                        margin-left: unset;
                        margin-right: auto;
                    }
                }
            }
        }
    }
}

@media (max-width: 480px) {
    section,    .section-t-space {
        padding-top: 30px;
    }
    .section-b-space {
        padding-bottom: 30px;
    }
    
    .top-category .top_box{
        margin-top: 30px;
    }
    header {
        .menu {
            .brand-logo {
                img {
                    width: 90px;
                }
            }
        }
        .mix-pills {
            margin-top: 0px;
            &.nav-pills {
                .nav-link {
                    svg {
                        display: none;
                    }
                }
            }
        }
    }
    .home_effect {
        .effect_image {
            img {
                bottom: -20px;
            }
        }
    }
    .home_section {
        .home {
            //height: 50vh;
            .home-content {
                //height: 50vh;
                h1 {
                    margin-top: -2px;
                }
                h5 {
                    margin-bottom: 0;
                }
                >div {
                    padding: 20px 60px;
                }
            }
        }
        .slick-prev,
        .slick-next {
            padding: 10px;
            &:after {
                font-size: 12px;
            }
        }
    }
    .cab-section {
        .cab-content {
            .car-select {
                display: block;
                ul {
                    margin-left: -4px;
                    margin-right: -4px;
                    li {
                        margin-right: 4px;
                        width: 32%;
                        display: inline-block;
                        padding: 8px 0;
                        margin-left: 4px;
                    }
                }
                .btn {
                    width: 38%;
                    margin: 10px auto 0;
                }
            }
            
            //form {
            //  .form-control {
            //    padding: 2px 10px;
            //  }
            //  .form-group {
            //    img {
            //      padding: 5px;
            //    }
            //  }
            //  .gj-datepicker,
            //  .gj-timepicker {
            //    button {
            //      width: 29px;
            //    }
            //  }
            //}
            .radio-form {
                margin-bottom: 0;
            }
            h3 {
                margin-bottom: 14px;
            }
        }
    }
    .medium-section {
        padding-top: 25px;
        padding-bottom: 25px;
    }
    .app-section {
        .app-content {
            .title {
                margin-bottom: 10px;
            }
            p {
                line-height: 1.4;
                margin-bottom: 8px;
            }
            h3 {
                margin-bottom: 10px;
            }
        }
        .app-box {
            padding: 20px;
            h2,
            h5 {
                margin-bottom: 8px;
            }
            p {
                margin-bottom: 12px;
                line-height: 1.4;
            }
            .app-buttons {
                a {
                    padding: 6px 10px;
                }
                .app-btn {
                    margin-left: 4px;
                }
            }
        }
    }
    .service-section {
        .service-box {
            padding: 20px;
            h3 {
                margin-bottom: 6px;
                font-size: 18px;
            }
            .service-icon {
                margin-bottom: 14px;
            }
            .btn {
                margin-top: 16px;
            }
        }
    }
    .service_section {
        .col-6 {
            max-width: 100%;
            flex: 0 0 100%;
        }
        >div {
            &:nth-last-child(-n + 5) {
                margin-top: 10px;
            }
        }
    }
    .service-part {
        .service-wrapper {
            padding: 25px;
            h6 {
                margin-bottom: 10px;
            }
            h3 {
                margin-bottom: 5px;
            }
            p {
                margin-bottom: 18px;
            }
        }
    }
    .testimonial-section {
        .testimonial {
            padding-bottom: 30px;
        }
    }
    .subscribe-section {
        .input-group {
            .btn {
                padding: 9px;
            }
            .form-control {
                font-size: 14px;
            }
        }
    }
    .testimonial_section {
        .testimonial {
            .top-part {
                .img-part {
                    margin-bottom: 28px;
                    margin-top: 30px;
                    img {
                        width: 90px;
                        height: 90px;
                    }
                    .heart-icon {
                        padding: 6px;
                        font-size: 12px;
                        bottom: -10%;
                    }
                }
            }
            .bottom-part {
                p {
                    margin-bottom: 16px;
                }
                .quote-icon {
                    i {
                        font-size: 55px;
                    }
                }
            }
        }
    }
    .about-section {
        .about-text {
            h3,
            h2 {
                margin-bottom: 4px;
            }
        }
        &.three-image {
            .about-text {
                h2 {
                    line-height: 20px;
                }
            }
            .image-section {
                .img-box {
                    +.img-box {
                        margin-left: 10px;
                    }
                }
            }
        }
    }
    .ticket-section {
        .ticket-box {
            .content {
                padding: 15px;
                margin: -30px auto 0 auto;
                width: 90%;
                box-shadow: -2px 11px 12px 0px rgba(black, 0.08);
            }
            .ticket-title {
                margin-bottom: -14px;
                h6 {
                    padding: 6px;
                }
                span {
                    padding: 7px;
                }
            }
            .btn {
                padding: 7px 14px;
            }
        }
        &.white-section {
            .ticket-box {
                margin-bottom: 30px;
                .content {
                    .detail {
                        h4,
                        h2 {
                            margin-bottom: 5px;
                        }
                        p {
                            margin-bottom: 10px;
                        }
                        h6 {
                            margin-bottom: 7px;
                        }
                    }
                }
            }
        }
    }
    .video-section {
        padding: 50px 0;
        .basic-section {
            h4 {
                line-height: 1.3;
                margin-bottom: 10px;
            }
            h2 {
                margin-bottom: 10px;
            }
        }
    }
    .video_section {
        padding: 50px 0;
        .video-content {
            .bottom-section {
                display: block;
                .btn {
                    display: block;
                    width: 40%;
                    margin: 0 auto 15px auto;
                }
                .info-btn {
                    width: 50%;
                    display: inline-block;
                    margin-left: 0;
                    h6 {
                        font-size: 11px;
                        padding: 14px 16px;
                    }
                    &.red-info {
                        width: 42%;
                        display: inline-block;
                    }
                }
            }
            h5 {
                margin-bottom: 8px;
            }
            h2 {
                margin-bottom: 8px;
            }
            p {
                line-height: 1.4;
            }
        }
    }
    .blog-section {
        .blog-box {
            display: block;
            margin-bottom: 8px;
            .blog-content {
                padding: 20px;
            }
        }
        &.section-b-space {
            padding-bottom: 22px;
        }
    }
    .blog_section {
        &.section-b-space {
            padding-bottom: 0;
        }
        &.destination-section {
            .destination-details {
                padding: 25px;
                h5 {
                    margin-bottom: 10px;
                    letter-spacing: 0.3em;
                }
                h2 {
                    margin-bottom: 8px;
                }
            }
        }
    }
    .special-section {
        &.section-b-space {
            padding-bottom: 0;
        }
        &.grid-box {
            .special-box {
                .special-content {
                    .tour-detail {
                        .bottom-section {
                            .price {
                                margin-top: 10px;
                            }
                        }
                        .include-sec {
                            margin-top: 8px;
                        }
                    }
                }
            }
        }
    }
    .full-banner {
        padding: 40px 0;
        .banner-content {
            text-align: center;
            >div {
                padding: 20px;
            }
            h2,
            h5 {
                margin-bottom: 5px;
            }
            .bottom-section {
                display: block;
                .btn {
                    display: block;
                    width: 40%;
                    margin: 0 auto 15px auto;
                }
                .info-btn {
                    width: 70%;
                    display: inline-block;
                    margin-left: 0;
                    text-align: center;
                    h6 {
                        font-size: 12px;
                        padding: 10px 12px;
                    }
                    &.red-info {
                        width: 30%;
                        display: inline-block;
                    }
                }
            }
        }
        .offer-text {
            h6 {
                &:after {
                    display: none;
                }
            }
        }
        &.banner-section {
            .place-image {
                .animation-place {
                    .plane-r {
                        right: -14%;
                        top: 0;
                        img {
                            width: 40%;
                        }
                    }
                    .cloud-r {
                        right: 36%;
                        top: 4%;
                        img {
                            width: 64%;
                        }
                    }
                    .cloud-l {
                        top: 2%;
                        img {
                            width: 45%;
                        }
                    }
                }
            }
        }
    }
    .category-section {
        .category-box {
            margin-bottom: 10px;
            .side-effect {
                &:after {
                    top: 5px;
                }
                &:before {
                    top: 10px;
                }
            }
            .top-bar {
                top: 15px;
                left: 15px;
                margin-bottom: 0;
                .offer {
                    padding: 2px 9px;
                    margin-right: 6px;
                }
            }
            .like-cls {
                right: 15px;
                top: 15px;
                i {
                    padding: 7px;
                    border-radius: 100%;
                    font-size: 10px;
                }
            }
            .bottom-bar {
                left: 15px;
                bottom: 15px;
                p {
                    font-size: 12px;
                }
                .top {
                    h5 {
                        max-width: 100px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        font-size: 13px;
                        margin-right: 5px;
                    }
                    h6 {
                        margin-left: 8px;
                        font-size: 13px;
                    }
                    .rating {
                        i {
                            font-size: 10px;
                            margin: 0 1px;
                        }
                    }
                }
            }
        }
    }
    .category-sec {
        .category-box {
            .img-category {
                .top-bar {
                    left: 15px;
                    top: 15px;
                }
                .like-cls {
                    i {
                        font-size: 15px;
                        padding: 10px;
                    }
                }
            }
            .content-category {
                margin-bottom: 30px;
            }
        }
    }
    .category-wrapper {
        &.section-b-space {
            padding-bottom: 0;
        }
        .category-wrap {
            .category-img {
                img {
                    width: 110px;
                    height: 110px;
                }
                .side-effect {
                    display: none;
                }
            }
            .category-content {
                padding: 20px 15px 20px 68px;
                p {
                    display: none;
                }
                .top {
                    h3 {
                        font-size: 14px;
                        margin-bottom: 0;
                    }
                    h6 {
                    }
                    .like-cls {
                        i {
                            font-size: 10px;
                            padding: 5px;
                        }
                    }
                }
                .bottom {
                    .coupon-code {
                        font-size: 10px;
                    }
                }
            }
            &:hover {
                .category-img {
                    left: 0;
                }
            }
        }
    }
    .category-bg {
        .category-block {
            padding: 60px 20px 30px;
            max-width: 90%;
        }
        &.section-b-space {
            padding-bottom: 20px;
        }
    }
    .routes-section {
        .row {
            >div {
                flex: 0 0 100%;
                max-width: 100%;
                &:nth-child(-n + 3) {
                    margin-bottom: 30px;
                }
            }
        }
    }
    .flight-detail {
        .flight-box {
            .middle-section {
                width: 40%;
                .flight-info {
                    &:after {
                        display: none;
                    }
                }
            }
            .name-section {
                width: 150px;
                h6 {
                    letter-spacing: 0.01em;
                    margin-bottom: 5px;
                }
                .btn {
                    padding: 2px 7px;
                    font-size: 12px;
                }
            }
        }
    }
    .discount-banner {
        padding: 40px 0;
        .banner-content {
            background-color: rgba($theme-color1, 0.85);
        }
    }
    .menu-section {
        .menu-box {
            .bottom-bar {
                padding: 20px;
                .menu-bar {
                    padding-bottom: 20px;
                    margin-bottom: 20px;
                    img {
                        margin-right: 0;
                        width: 80%;
                    }
                    .content {
                        h5 {
                            margin-bottom: 5px;
                        }
                        .rating {
                            margin-bottom: 8px;
                        }
                        h6 {
                            label {
                                position: relative;
                                right: 0;
                                top: 0;
                                display: inline-block;
                            }
                            del {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    .book-table {
        background-position: unset;
        .table-form {
            padding: 15px;
        }
    }
    .tap-top {
        bottom: 20px;
        width: 22px;
        height: 22px;
        right: -38px;
        i {
            margin-top: -3px;
            font-size: 17px;
        }
        &.top {
            right: 20px;
        }
    }
    .highlight-section {
        .row {
            >div {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
        .highlight-box {
            svg,
            img {
                width: 35px;
            }
        }
    }
    // inner pages
    .filter-panel {
        padding: 15px 20px;
    }
    .mobile-filter {
        padding: 15px 20px;
    }
    .list-view {
        .list-box {
            .list-content {
                .book-now {
                    display: none;
                }
            }
        }
    }
    .single-section {
        .description-section {
            .description-details {
                .menu-part {
                    .rooms-box {
                        .price-details {
                            align-items: flex-start;
                            justify-content: flex-start;
                            text-align: left;
                            padding-top: 10px;
                            border-top: 1px solid $inner-bg;
                        }
                        td {
                            &:first-child {
                                width: 100%;
                                display: block;
                            }
                            &:nth-child(2) {
                                width: 100%;
                                display: inline-block;
                            }
                            &:nth-child(3) {
                                width: 100%;
                                display: inline-block;
                            }
                        }
                        .room-detail {
                            padding: 10px 0;
                        }
                    }
                }
            }
            &.tab-section {
                .menu-top {
                    li {
                        a {
                            padding: 8px;
                        }
                    }
                }
            }
        }
    }
    .single-section {
        .description-section {
            .description-details {
                .menu-part {
                    .accordion-plan {
                        .card {
                            .card-header {
                                padding: 10px;
                                button {
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    max-width: 200px;
                                    white-space: nowrap;
                                    padding: 0;
                                }
                            }
                            .card-body {
                                padding: 10px;
                            }
                        }
                    }
                    &.facility {
                        h6 {
                            font-size: 13px;
                            img {
                                width: 15px;
                            }
                        }
                        ul {
                            margin-left: 0;
                            li {
                                font-size: 12px;
                                i {
                                    margin-right: 2px;
                                }
                            }
                        }
                    }
                    &.review {
                        .review-box {
                            .rating {
                                display: block;
                                span {
                                    display: block;
                                    margin-left: 0;
                                }
                            }
                        }
                    }
                }
                .content-title {
                }
            }
        }
    }
    .bird-animation {
        opacity: 0.5;
    }
    .view-map {
        .arrow {
            left: 30px;
        }
    }
    .left-sidebar {
        &.sidebar-popup {
            top: 60px;
        }
    }
    .order-menu-section {
        .order-section {
            .order-items {
                .items {
                    p {
                        max-width: 100%;
                    }
                    .addtocart_btn {
                        position: relative;
                        top: 0;
                        margin-top: 10px;
                        margin-left: auto;
                        width: 100%;
                        .add_cart {
                            span {
                                display: none;
                            }
                        }
                    }
                    +.items {
                        .addtocart_btn {
                            top: 0;
                        }
                    }
                }
            }
        }
    }
    .flight-search {
        .responsive-detail {
            padding: 15px 20px;
            .modify-search {
                right: 15px;
            }
        }
    }
    .flight-search {
        .flight-search-detail {
            form {
                padding: 10px 0;
                .responsive-close {
                    right: -20px;
                    i {
                        font-size: 16px;
                    }
                }
            }
        }
    }
    .flight-detail-sec {
        .detail-bar {
            .detail-wrap {
                .airport-part {
                    width: 100%;
                }
            }
        }
    }
    .top-bar-flight {
        .fare-calender {
            .calender-external {
                padding: 15px;
                .fc th,
                .fc td {
                    width: 30px;
                    font-size: 12px;
                }
                .fc-dayGrid-view .fc-body .fc-row {
                    height: 30px !important;
                    min-height: 30px;
                }
                .fc-content {
                    font-size: 8px;
                }
                tr:first-child>td>.fc-day-grid-event {
                    margin-top: -5px;
                }
                .fc-button {
                    padding: 3px;
                    font-size: 10px;
                }
                .fc-toolbar h2 {
                    font-size: 16px;
                }
                .fc-toolbar.fc-header-toolbar {
                    margin-bottom: 10px;
                }
            }
        }
    }
    .portfolio-section {
        .filter-button-group {
            ul {
                li {
                    padding: 3px 10px;
                    margin: 0 5px;
                }
            }
        }
    }
    .blog-sidebar {
        .blog-wrapper {
            .sidebar-content {
                .blog-post {
                    li {
                        width: 100%;
                    }
                }
            }
            .sidebar-title {
                h5 {
                    margin-bottom: 15px;
                }
            }
            .search-bar {
                input {
                    padding: 10px 14px 10px 50px;
                }
            }
        }
    }
    .blog-inner {
        &.blog_section {
            .blog-wrap {
                .blog-details {
                    padding: 15px;
                }
            }
            .blog-list {
                .blog-wrap {
                    .blog-details {
                        h6 {
                            i {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    .dashboard-section {
        .dashboard-box {
            .dashboard-detail {
                ul {
                    li {
                        .details {
                            .left {
                                width: 70px;
                            }
                        }
                    }
                }
            }
        }
    }
    .full-page {
        .single-section {
            .description-section {
                .description-details {
                    .menu-part {
                        .list-view {
                            .list-box {
                                .list-img {
                                    width: 50%;
                                    margin: 0 auto;
                                }
                                .list-content {
                                    .facility-icon {
                                        .facility-box {
                                            span {
                                                font-size: 0;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    // skeleton loader
    .skeleton_loader {
        .flight-search {
            .responsive-detail {
                .modify-search {
                    .ldr-btn {
                        width: 106px;
                        height: 28px;
                    }
                }
            }
        }
        .single-section {
            .description-section {
                .description-details {
                    .menu-part {
                        .rooms-box {
                            .zoom-gallery {
                                width: 100%;
                            }
                            .price-details {
                                h6,
                                h5 {
                                    margin-left: unset;
                                    margin-right: auto;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 420px) {
    .breadcrumb-section {
        &.breadcrumb-cum-header {
            .breadcrumb-content {
                h2 {
                    margin-bottom: 0;
                }
                .package-detail {
                    li {
                        display: block;
                    }
                }
            }
        }
    }
    .service-part {
        .service-wrapper {
            padding: 18px;
            h3 {
                font-size: 18px;
            }
        }
    }
    .testimonial-section {
        .testimonial {
            .left-part {
                img {
                    width: 80px;
                    height: 80px;
                }
                .design {
                    width: 30px;
                    height: 30px;
                    i {
                        font-size: 16px;
                    }
                    .light {
                        font-size: 32px;
                    }
                }
            }
        }
    }
    .ticket-section {
        .ticket-box {
            .content {
                .detail {
                    h3,
                    h4 {
                        margin-bottom: 5px;
                    }
                    p {
                        margin-bottom: 10px;
                        line-height: 1.3;
                    }
                }
            }
            .btn {
                padding: 6px 10px;
            }
        }
        .slick-prev,
        .slick-next {
            top: 30%;
        }
    }
    .video-section {
        padding: 40px 0;
    }
    .video_section {
        padding: 40px 0;
    }
    .blog_section {
        .blog-wrap {
            .blog-details {
                padding: 20px;
            }
            .blog-image {
                .blog-label {
                    padding: 7px 10px;
                    h6 {
                        font-size: 12px;
                    }
                }
            }
        }
    }
    .filter-section {
        .top-section {
            .coupon {
                position: absolute;
                bottom: 20px;
            }
            .title {
                margin-bottom: 18px;
                h2 {
                    margin-bottom: 0;
                }
            }
        }
        .filters {
            .filter-btn {
                padding: 8px 18px;
            }
        }
    }
    .single-section {
        .description-section {
            .menu-top {
                li {
                    a {
                        padding: 0 10px 0 0;
                    }
                }
            }
            .description-details {
                .menu-part {
                    .rooms-box {
                        td {
                            &:nth-child(2) {
                                width: 100%;
                                display: inline-block;
                            }
                            &:nth-child(3) {
                                width: 100%;
                                display: inline-block;
                            }
                        }
                        .price-details {
                            align-items: flex-start;
                            justify-content: flex-start;
                            text-align: left;
                            padding-top: 10px;
                            border-top: 1px solid $inner-bg;
                        }
                    }
                    .accordion-plan {
                        .card {
                            .card-header {
                                padding: 10px;
                                button {
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    max-width: 200px;
                                    white-space: nowrap;
                                    padding: 0;
                                }
                            }
                            .card-body {
                                padding: 10px;
                            }
                        }
                    }
                    &.facility {
                        h6 {
                            font-size: 12px;
                            img {
                                width: 15px;
                            }
                        }
                        ul {
                            margin-left: 0;
                            li {
                                font-size: 12px;
                                i {
                                    margin-right: 2px;
                                }
                            }
                        }
                    }
                    &.review {
                        .review-box {
                            .rating {
                                display: block;
                                span {
                                    display: block;
                                    margin-left: 0;
                                }
                            }
                        }
                    }
                }
                .content-title {
                }
            }
        }
    }
    .flight-search {
        .responsive-detail {
            .details {
                span {
                    display: block;
                    font-size: 12px;
                    &.divider {
                        display: none;
                    }
                }
            }
        }
    }
    .contact_section {
        &.contact_right {
            .row {
                >div {
                    flex: 0 0 100%;
                    max-width: 100%;
                    &:last-child {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
    .dashboard-section {
        .dashboard-box {
            .dashboard-detail {
                .booking-box {
                    .detail-middle {
                        .media {
                            .icon {
                                display: none;
                            }
                            .media-body {
                                margin-left: 0;
                            }
                        }
                    }
                }
                .card-payment {
                    .payment-card {
                        &.add-card {
                            .card-details {
                                min-height: 124px;
                            }
                        }
                    }
                }
            }
        }
        .dashboard-sidebar {
            .profile-top {
                .profile-image {
                    .profile-edit {
                        right: 26%;
                    }
                }
            }
        }
    }
    .hotel-single-section {
        .hotel-title-section {
            .hotel-name {
                .left-part {
                    .top {
                        .rating {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .hotel_title_section {
        .hotel-name {
            .left-part {
                .top {
                    .rating {
                        display: none;
                    }
                }
            }
        }
    }
    .top-banner {
        .top-banner-content {
            right: -40px;
        }
    }
    .video-slider {
        height: 188px;
    }
}

@media (max-width: 360px) {
    .video-slider {
        height: 146px;
    }
}

@media (max-width: 320px) {
    .cab-section {
        height: 110vh;
        .cab-content {
            height: 110vh;
        }
    }
}
