.Loader {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.rbt-menu.dropdown-menu.show {
  width: auto !important;
  min-width: 193px;
}

