$theme-color1:#ef3f3e;
$theme-color2: #00a2f7;
//fonts
$font_0: Nunito;
$font_1: sans-serif;
$font_2: Work Sans;
$font_3: Vampiro One;
$font_4: cursive;
$font_5: Dancing Script;
$font_6: Pacifico;
$font_7: Bangers;
$font-themify: themify;
$font-awesome: FontAwesome;
//colors
$grey-light:#bcbcbc;
$grey-light2:#b2b2b2;
$grey-light-2:#b3b3b3;
$title-grey:#808080;
$grey-1:#cacaca;
$grey-2: #a5a5a5;
$grey-3: #333333;
$grey-4:#f9f9f9;
$grey-5:#d1d1d1;
$grey-6:#707070;
$grey-7:#646464;
$grey-8:#cccccc;
$grey-9: #999999;
$grey-10: #adadad;
$grey-12: #565857;
$grey-13: #9a9a9a;
$footer-color: #a1a1a1;
$rate-yellow: #ffcc33;
$red-heart:#e1002c;
$footer-bg: #1b1b1b;
$footer-bottom: #1e1e1e;
$footer-social: #717171;
$youtube-red: #d62423;
$box-shadow:#dedede;
$grey-darker: #222222;
$light_grey: #f5f5f5;
$inner-bg:#fafafa;
// radious 
$radious5: 5px;
$radious10: 10px;
$radious15: 15px;
$radious20: 20px;
$radious25: 25px;
// skeleton loader colors
$bg-loader: #f3f3f3;
// dark layout variable //
$dark-body:#2b2b2b;
$dark-main:#232323;
$dark-border:#404040;
$dark-link:#cbcbcb;
$dark-span:#929292;
$white-1:#ffffff; // for h1
$white-2:#f1f3f5; // for h2
$white-3:#eaedef; // for h3
$white-4:#e7eaec; // for h4
$white-5:#dee2e6; // for h5
$white-6:#cfd4da; // for h6